import $ from 'jquery';

export default class News {
    constructor() {
        this.$modal = $('#modalConfirmDelNews');
        if (!this.$modal.length) {
            let html = `<div id="modalConfirmDelNews" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <p>Вы уверены, что хотите удалить данную новость?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Отмена
                </button>
                <button type="button" class="btn btn-primary btn-yes">
                    <span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Да
                </button>
            </div>
        </div>
    </div>
</div>`;
            this.$modal = $(html);
            this.$modal.appendTo('body');
        }
    }

    events() {
        this.$modal
            .on('shown.bs.modal.top', event => {
                let $self = $(event.currentTarget);
                let top = $(window).height() / 2 - $self.find('.modal-content').height() / 2;
                $self.find('.modal-dialog').css('margin-top', 0 < top ? top : '');
            })
            .on('hidden.bs.modal.clear', () => {
                this.$modal.removeAttr('data-id');
                this.$modal.removeData('id');
            })
            .on('click.confirm', '.btn-yes', () => {
                let id = this.$modal.data().id;
                this.remove(id).then(data => {
                    this.$modal.modal('hide');
                    this.callbackAfterRemove(data);
                }).catch(error => {
                    console.error(error);
                });
            })
        ;
    }

    callbackAfterRemove(data) {
    }

    handlerConfirmRemove(event) {
        event.preventDefault();
        let $self = $(event.currentTarget);
        let id = $self.data().id;
        this.$modal.attr('data-id', id);
        this.$modal.data('id', id);
        this.$modal.modal('show');
    }

    async get(id) {
        const response = await fetch('/?method=getNewsById', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $.param({id: id})
        });
        return await response.json();
    }

    async set(formData) {
        const response = await fetch('/?method=setNews', {
            method: 'POST',
            credentials: 'same-origin',
            body: formData
        });
        return await response.json();
    }

    async remove(id) {
        const response = await fetch('/?method=removeNewsById', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $.param({id: id})
        });
        return await response.json();
    }
}
