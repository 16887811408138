import $ from 'jquery';

export default class FilterModalNews {
    constructor($modal) {
        this.$modal = $modal;
        this.$accessToOrders = this.$modal.find('#accessToOrders');
        this.$showPackedRemains = this.$modal.find('#showPackedRemains');
        this.$clientType = this.$modal.find('#clientType');
        this.$state = this.$modal.find('#state');
        this.$filial = this.$modal.find('#filial');
        this.$region = this.$modal.find('#region');
        this.$city = this.$modal.find('#city');
        this.getStates().then(data => {
            let items = Object.values(data);
            if (items.length) {
                items.sort(this._sortByName).forEach(item => {
                    this.$state.append(`<option value="${item.id}">${item.name}</option>`);
                });
                this.$state.prop('disabled', false).selectpicker('refresh');
                this.callbackStatesLoaded();
            }
        }).catch(error => {
            console.error(error)
        });
        this.getClientTypes().then(data => {
            let items = Object.values(data);
            if (items.length) {
                items.forEach(item => {
                    this.$clientType.append(`<option value="${item.id}">${item.name}</option>`);
                });
                this.$clientType.prop('disabled', false).selectpicker('refresh');
                this.callbackClientTypesLoaded();
            }
        }).catch(error => {
            console.error(error)
        });
        this.$modal
            .on('changed.bs.select', '#state', (event, clickedIndex, isSelected) => {
                let $options = this.$state.find('option');
                let $option = $options.eq(clickedIndex);
                let stateId = +$option.val();
                this._removeOptgroup(this.$city, `optgroup[data-state-id="${stateId}"]`);
                if (isSelected) {
                    let stateName = $option.text();
                    this._getById(this.getFilialByStateId(stateId), stateName, this.$filial, {'state-id': stateId});
                    this._getById(this.getRegionByStateId(stateId), stateName, this.$region, {'state-id': stateId});
                } else {
                    this._removeOptgroup(this.$filial, `optgroup[data-state-id="${stateId}"]`);
                    this._removeOptgroup(this.$region, `optgroup[data-state-id="${stateId}"]`);
                }
            })
            .on('changed.bs.select', '#filial', (event, clickedIndex, isSelected) => {
                let $option = this.$filial.find('option').eq(clickedIndex);
                let filialId = +$option.val();
                let $optgroup = $option.parent();
                let stateId = $optgroup.data().stateId;
                let stateName = $optgroup.attr('label');
                if (isSelected) {
                    this._removeOptgroup(this.$region, `optgroup[data-state-id="${stateId}"]:not([data-filial-id])`);
                    this._removeOptgroup(this.$city, `optgroup[data-state-id="${stateId}"]:not([data-filial-id])`);
                    let filialName = $option.text();
                    let label = `${stateName}, филиал ${filialName}`;
                    this._getById(this.getRegionByFilialId(filialId), label, this.$region, {
                        'filial-id': filialId,
                        'state-id': stateId,
                    });
                } else {
                    this._removeOptgroup(this.$region, `optgroup[data-filial-id="${filialId}"]`);
                    this._removeOptgroup(this.$city, `optgroup[data-filial-id="${filialId}"]`);
                    if (!this.$region.children().length) {
                        this._getById(this.getRegionByStateId(stateId), stateName, this.$region, {'state-id': stateId});
                    }
                }
            })
            .on('changed.bs.select', '#region', (event, clickedIndex, isSelected) => {
                let $option = this.$region.find('option').eq(clickedIndex);
                let regionId = +$option.val();
                let $optgroup = $option.parent();
                let filialId = $optgroup.data().filialId;
                let stateId = $optgroup.data().stateId;
                if (isSelected) {
                    this.$city.prop('disabled', true).selectpicker('refresh');
                    let regionName = $option.text();
                    let parentLabel = $optgroup.attr('label');
                    let label = `${parentLabel}, ${regionName}`;
                    this._getById(this.getCityByRegionId(regionId, filialId), label, this.$city, {
                        'region-id': regionId,
                        'filial-id': filialId,
                        'state-id': stateId
                    });
                } else {
                    if (filialId) {
                        this._removeOptgroup(this.$city, `optgroup[data-filial-id="${filialId}"]`);
                    } else {
                        this._removeOptgroup(this.$city, `optgroup[data-state-id="${stateId}"]`);
                    }
                }
            })
        ;
    }

    callbackStatesLoaded() {
    }

    callbackClientTypesLoaded() {
    }

    setClientType(clientType) {
        if (!this.$clientType.children().length) {
            this.callbackClientTypesLoaded = this.setClientType.bind(this, clientType);
            return false;
        }
        clientType.forEach(value => {
            let $option = this.$clientType.children(`[value="${value}"]`);
            $option.prop('selected', true);
            this.$clientType.selectpicker('refresh');
        });
        return true;
    }

    getLocation() {
        let items = [];
        let $stateCheckedOptions = this.$state.children(':checked');
        $stateCheckedOptions.each((index, element) => {
            let stateId = +element.value;
            let stateItem = {state_id: stateId};
            let $filialCheckedOptions = this.$filial.children(`[data-state-id="${stateId}"]`).children(':checked');
            if ($filialCheckedOptions.length) {
                $filialCheckedOptions.each((index, element) => {
                    let filialId = +element.value;
                    let filialItem = Object.assign({filial_id: filialId}, stateItem);
                    let $regionCheckedOptions = this.$region
                        .children(`[data-state-id="${stateId}"][data-filial-id="${filialId}"]`)
                        .children(':checked');
                    if ($regionCheckedOptions.length) {
                        $regionCheckedOptions.each((index, element) => {
                            let regionId = +element.value;
                            let regionItem = Object.assign({region_id: regionId}, filialItem);
                            let $cityCheckedOptions = this.$city
                                .children(`[data-state-id="${stateId}"][data-filial-id="${filialId}"][data-region-id="${regionId}"]`)
                                .children(':checked');
                            if ($cityCheckedOptions.length) {
                                $cityCheckedOptions.each((index, element) => {
                                    let cityId = +element.value;
                                    let cityItem = Object.assign({city_id: cityId}, regionItem);
                                    items.push(cityItem);
                                });
                            } else {
                                items.push(regionItem);
                            }
                        });
                    } else {
                        items.push(filialItem);
                    }
                });
            }
            let $regionCheckedOptions = this.$region
                .children(`[data-state-id="${stateId}"]:not([data-filial-id])`)
                .children(':checked');
            if ($regionCheckedOptions.length) {
                $regionCheckedOptions.each((index, element) => {
                    let regionId = +element.value;
                    let regionItem = Object.assign({region_id: regionId}, stateItem);
                    let $cityCheckedOptions = this.$city
                        .children(`[data-state-id="${stateId}"][data-region-id="${regionId}"]:not([data-filial-id])`)
                        .children(':checked');
                    if ($cityCheckedOptions.length) {
                        $cityCheckedOptions.each((index, element) => {
                            let cityId = +element.value;
                            let cityItem = Object.assign({city_id: cityId}, regionItem);
                            items.push(cityItem);
                        });
                    } else {
                        items.push(regionItem);
                    }
                });
            }
            if (!$filialCheckedOptions.length && !$regionCheckedOptions.length) {
                items.push(stateItem);
            }
        });
        return items;
    }

    setLocation(location) {
        if (!this.$state.children().length) {
            this.callbackStatesLoaded = this.setLocation.bind(this, location);
            return false;
        }
        location.forEach(item => {
            let stateId = item.state_id;
            if (!stateId) {
                return;
            }
            let filialId = item.filial_id;
            let regionId = item.region_id;
            let cityId = item.city_id;
            let $option = this.$state.children(`[value="${stateId}"]`);
            $option.prop('selected', true);
            this.$state.selectpicker('refresh');
            let stateName = $option.text();
            this._getById(this.getFilialByStateId(stateId), stateName, this.$filial, {'state-id': stateId}).then(() => {
                if (!filialId) {
                    return;
                }
                let $option = this.$filial
                    .children(`[data-state-id="${stateId}"]`)
                    .children(`[value="${filialId}"]`);
                $option.prop('selected', true);
                this.$filial.selectpicker('refresh');
                let $optgroup = $option.parent();
                let parentLabel = $optgroup.attr('label');
                let filialName = $option.text();
                let label = `${parentLabel}, филиал ${filialName}`;
                this._getById(this.getRegionByFilialId(filialId), label, this.$region, {
                    'filial-id': filialId,
                    'state-id': stateId,
                }).then(() => {
                    if (!regionId) {
                        return;
                    }
                    let $option = this.$region
                        .children(`[data-state-id="${stateId}"][data-filial-id="${filialId}"]`)
                        .children(`[value="${regionId}"]`);
                    $option.prop('selected', true);
                    this.$region.selectpicker('refresh');
                    let $optgroup = $option.parent();
                    let parentLabel = $optgroup.attr('label');
                    let regionName = $option.text();
                    let label = `${parentLabel}, ${regionName}`;
                    this._getById(this.getCityByRegionId(regionId), label, this.$city, {
                        'region-id': regionId,
                        'filial-id': filialId,
                        'state-id': stateId
                    }).then(() => {
                        if (!cityId) {
                            return;
                        }
                        let $option = this.$city
                            .children(`[data-state-id="${stateId}"][data-filial-id="${filialId}"][data-region-id="${regionId}"]`)
                            .children(`[value="${cityId}"]`);
                        $option.prop('selected', true);
                        this.$city.selectpicker('refresh');
                    });
                });
            });
            if (filialId) {
                return;
            }
            this._getById(this.getRegionByStateId(stateId), stateName, this.$region, {'state-id': stateId}).then(() => {
                if (!regionId) {
                    return;
                }
                let $option = this.$region
                    .children(`[data-state-id="${stateId}"]:not([data-filial-id])`)
                    .children(`[value="${regionId}"]`);
                $option.prop('selected', true);
                this.$region.selectpicker('refresh');
                let $optgroup = $option.parent();
                let parentLabel = $optgroup.attr('label');
                let regionName = $option.text();
                let label = `${parentLabel}, ${regionName}`;
                this._getById(this.getCityByRegionId(regionId), label, this.$city, {
                    'region-id': regionId,
                    'state-id': stateId
                }).then(() => {
                    if (!cityId) {
                        return;
                    }
                    let $option = this.$city
                        .children(`[data-state-id="${stateId}"][data-region-id="${regionId}"]:not([data-filial-id])`)
                        .children(`[value="${cityId}"]`);
                    $option.prop('selected', true);
                    this.$city.selectpicker('refresh');
                });
            });
        });
        return true;
    }

    clear() {
        this.$accessToOrders.selectpicker('val', -1);
        this.$showPackedRemains.selectpicker('val', -1);
        this.$clientType.selectpicker('val', '');
        this.$state.selectpicker('val', '');
        this.$filial.empty().prop('disabled', true).selectpicker('refresh');
        this.$region.empty().prop('disabled', true).selectpicker('refresh');
        this.$city.empty().prop('disabled', true).selectpicker('refresh');
    }

    _sortByName(a, b) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    }

    _generateOptgroup(label, items) {
        let html = `<optgroup label="${label}"></optgroup>`;
        let $optgroup = $(html);
        items.sort(this._sortByName).forEach(item => {
            $optgroup.append(`<option value="${item.id}">${item.name}</option>`);
        });
        return $optgroup;
    }

    _removeOptgroup($select, selector) {
        $select.find(selector).remove();
        if (!$select.children().length) {
            $select.prop('disabled', true);
        }
        $select.selectpicker('refresh');
    }

    _getById(method, label, $select, props) {
        $select.prop('disabled', true).selectpicker('refresh');
        return method.then(data => {
            let items = Object.values(data);
            if (items.length) {
                let $optgroup = this._generateOptgroup(label, items);
                for (let key in props) {
                    if (props.hasOwnProperty(key)) {
                        $optgroup.attr(`data-${key}`, props[key]);
                    }
                }
                $optgroup.data(props);
                $select.prop('disabled', false).append($optgroup).selectpicker('refresh');
            } else if ($select.children().length) {
                $select.prop('disabled', false).selectpicker('refresh');
            }
        }).catch(error => {
            console.error(error)
        });
    }

    async getStates() {
        const response = await fetch('/?method=getStates', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return await response.json();
    }

    async getFilialByStateId(stateId) {
        const response = await fetch('/?method=getFilialByStateId', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $.param({state_id: stateId})
        });
        return await response.json();
    }

    async getRegionByStateId(stateId) {
        const response = await fetch('/?method=getRegionByStateId', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $.param({state_id: stateId})
        });
        return await response.json();
    }

    async getRegionByFilialId(filialId) {
        const response = await fetch('/?method=getRegionByFilialId', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $.param({filial_id: filialId})
        });
        return await response.json();
    }

    async getCityByRegionId(regionId, filialId) {
        let obj = {region_id: regionId};
        if (filialId) {
            obj.filial_id = filialId;
        }
        const response = await fetch('/?method=getCityByRegionId', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $.param(obj)
        });
        return await response.json();
    }

    async getClientTypes() {
        const response = await fetch('/?method=getClientTypes', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return await response.json();
    }
}
