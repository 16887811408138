import $ from 'jquery';
import News from './news';
import FilterModalNews from './filter.modal.news';

export default class ModalBannerNews {
    constructor() {
        this.news = new News();
    }

    _prepare() {
        this.$title = this.$modal.find('#title');
        this.$bannerJpg = this.$modal.find('#bannerJpg');
        this.$bannerGif = this.$modal.find('#bannerGif');
        this.$priceListToggle = this.$modal.find('#priceListToggle');
        this.$priceList = this.$modal.find('#priceList');
        this.filter = new FilterModalNews(this.$modal);
        this.$btnSave = this.$modal.find('.btn-save');
        this.$modal
            .on('show.bs.modal.prepare', () => {
                let id = this.$modal.data('id');
                let title = id ? 'Редактирование новости' : 'Добавление новости';
                this.$modal.find('.modal-title').text(title);
            })
            .on('shown.bs.modal.focus', () => {
                this.$title.focus();
            })
            .on('hidden.bs.modal.clear', () => {
                this.$title.val('')
                    .closest('.form-group').removeClass('has-error')
                ;
                this.$bannerJpg.removeAttr('data-uri').removeData('uri').val('')
                    .closest('.form-group').removeClass('has-error')
                    .find('.help-block').hide().end()
                    .find('.banner-preview').empty()
                ;
                this.$bannerGif.removeAttr('data-uri').removeData('uri').val('')
                    .closest('.form-group').removeClass('has-error')
                    .find('.help-block').hide().end()
                    .find('.banner-preview').empty()
                ;
                this.$priceListToggle.prop('checked', false);
                this.$priceList.val('').prop('disabled', true)
                    .closest('.form-group').removeClass('has-error')
                    .find('.help-block').hide().end()
                    .find('.price-list-preview').empty()
                ;
                this.filter.clear();
                this.$btnSave.prop('disabled', false);
                this.$modal.removeAttr('data-id').removeData('id');
            })
            .on('change', '#bannerJpg', event => {
                let $formGroup = this.$bannerJpg.closest('.form-group');
                let $helpBlock = $formGroup.find('.help-block');
                let $bannerPreview = $formGroup.find('.banner-preview');
                $formGroup.removeClass('has-error');
                $helpBlock.hide();
                $bannerPreview.empty();
                Array.from(event.currentTarget.files).forEach(file => {
                    if ('image/jpeg' !== file.type) {
                        $formGroup.addClass('has-error');
                    }
                    let img = document.createElement('img');
                    img.src = URL.createObjectURL(file);
                    img.onload = event => {
                        if (500 !== event.target.naturalWidth || 500 !== event.target.naturalHeight) {
                            $formGroup.addClass('has-error');
                        }
                        if ($formGroup.hasClass('has-error')) {
                            $helpBlock.show();
                        }
                    };
                    if (this._isImageType(file)) {
                        $bannerPreview.append(img);
                    }
                });
            })
            .on('change', '#bannerGif', event => {
                let $formGroup = this.$bannerGif.closest('.form-group');
                let $helpBlock = $formGroup.find('.help-block');
                let $bannerPreview = $formGroup.find('.banner-preview');
                $formGroup.removeClass('has-error');
                $helpBlock.hide();
                $bannerPreview.empty();
                Array.from(event.currentTarget.files).forEach(file => {
                    if ('image/gif' !== file.type) {
                        $formGroup.addClass('has-error');
                    }
                    let img = document.createElement('img');
                    img.src = URL.createObjectURL(file);
                    img.onload = event => {
                        if (468 !== event.target.naturalWidth || 68 !== event.target.naturalHeight) {
                            $formGroup.addClass('has-error');
                        }
                        if ($formGroup.hasClass('has-error')) {
                            $helpBlock.show();
                        }
                    };
                    if (this._isImageType(file)) {
                        $bannerPreview.append(img);
                    }
                });
            })
            .on('change', '#priceListToggle', event => {
                this.$priceList.closest('.form-group').find('.price-list-preview').empty();
                this.$priceList.prop('disabled', !event.currentTarget.checked).val('');
            })
            .on('change', '#priceList', event => {
                let $formGroup = this.$priceList.closest('.form-group');
                let $helpBlock = $formGroup.find('.help-block');
                let $priceListPreview = $formGroup.find('.price-list-preview');
                $formGroup.removeClass('has-error');
                $helpBlock.hide();
                $priceListPreview.empty();
                Array.from(event.currentTarget.files).forEach(file => {
                    let uri = URL.createObjectURL(file);
                    let html = `<p><a class="dotted-blue" href="${uri}">${file.name}</a></p>`;
                    $priceListPreview.append(html);
                    let spreadsheetTypes = [
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ];
                    if (
                        !spreadsheetTypes.includes(file.type)
                        || 10 < file.size / (1024 * 1024)
                    ) {
                        $formGroup.addClass('has-error');
                        $helpBlock.show();
                    }
                });
            })
            .on('click', '.btn-save', event => {
                this.$title.closest('.form-group').removeClass('has-error');
                if (!this.$title.val().trim().length) {
                    this.$title.closest('.form-group').addClass('has-error');
                }
                if (this.$bannerJpg.closest('.form-group').find('.help-block').is(':hidden')) {
                    this.$bannerJpg.closest('.form-group').removeClass('has-error');
                    if (!this.$bannerJpg.data().uri && !this.$bannerJpg.get(0).files.length) {
                        this.$bannerJpg.closest('.form-group').addClass('has-error');
                    }
                }
                if (this.$bannerGif.closest('.form-group').find('.help-block').is(':hidden')) {
                    this.$bannerGif.closest('.form-group').removeClass('has-error');
                    if (!this.$bannerGif.data().uri && !this.$bannerGif.get(0).files.length) {
                        this.$bannerGif.closest('.form-group').addClass('has-error');
                    }
                }
                let $form = this.$modal.find('form');
                let $hasError = $form.find('.has-error');
                if (!$hasError.length) {
                    let formData = new FormData($form.get(0));
                    formData.append('type', '1');
                    let id = this.$modal.data().id;
                    if (id) {
                        formData.append('id', id.toString());
                    }
                    this.filter.getLocation().forEach((item, index) => {
                        for (let key in item) {
                            if (!item.hasOwnProperty(key)) {
                                continue;
                            }
                            let value = item[key];
                            formData.append(`location[${index}][${key}]`, value.toString());
                        }
                    });
                    this.$btnSave.prop('disabled', true);
                    this.news.set(formData).then(data => {
                        this.$modal.modal('hide');
                        this.callbackAfterClickSave(data);
                    }).catch(error => {
                        console.error(error);
                    });
                } else {
                    this.$modal.scrollTop($hasError.offset().top + this.$modal.scrollTop())
                }
            })
        ;
    }

    init(callback) {
        let $modal = $('#modalNewsBanner');
        if ($modal.length) {
            this.$modal = $modal;
            this._prepare();
            callback && callback();
            return true;
        }
    }

    callbackAfterClickSave(data) {
    }

    handlerAdd(event) {
        if (!this.$modal) {
            return this.init(this.handlerAdd.bind(this, event));
        }
        event.preventDefault();
        this.$modal.modal('show');
    }

    handlerEdit(event) {
        if (!this.$modal) {
            return this.init(this.handlerEdit.bind(this, event));
        }
        event.preventDefault();
        let $self = $(event.currentTarget);
        let id = $self.data().id;
        this.news.get(id).then(data => {
            let title = $('<textarea/>').html(data.title).text();
            this.$title.val(title);
            let files = JSON.parse(data.text);
            let img;
            img = document.createElement('img');
            img.src = files.banner.jpg;
            this.$bannerJpg.attr('data-uri', img.src).data('uri', img.src)
                .closest('.form-group').find('.banner-preview').append(img);
            img = document.createElement('img');
            img.src = files.banner.gif;
            this.$bannerGif.attr('data-uri', img.src).data('uri', img.src)
                .closest('.form-group').find('.banner-preview').append(img);
            if (files.price_list.length) {
                this.$priceListToggle.prop('checked', true);
                this.$priceList.prop('disabled', false);
                files.price_list.forEach(uri => {
                    let name = uri.split('/').reverse()[0]
                    let html = `<p><a class="dotted-blue" href="${uri}">${name}</a></p>`;
                    this.$priceList.closest('.form-group').find('.price-list-preview').append(html);
                });
            }
            let filter = data.filter;
            if (null !== filter.access_to_orders) {
                this.filter.$accessToOrders.selectpicker('val', +filter.access_to_orders);
            }
            if (null !== filter.show_packed_remains) {
                this.filter.$showPackedRemains.selectpicker('val', +filter.show_packed_remains);
            }
            if (null !== filter.client_type) {
                this.filter.setClientType(filter.client_type);
            }
            this.filter.setLocation(filter.location);
            this.$modal.attr('data-id', id).data('id', id);
            this.$modal.modal('show');
        }).catch(error => {
            console.error(error);
        });
    }

    _isImageType(file) {
        let imageTypes = [
            'image/apng',
            'image/bmp',
            'image/gif',
            'image/jpeg',
            'image/pjpeg',
            'image/png',
            'image/svg+xml',
            'image/tiff',
            'image/webp',
            'image/x-icon'
        ];
        return imageTypes.includes(file.type);
    }
}
