/**
 * Created by Сергей on 04.04.14.
 */
import ModalNewCart from '../../assets/js/components/cart/modal.new.cart';

var _gaq = _gaq || [];
_gaq.push(['_setAccount', 'UA-18776995-3']);
_gaq.push(['_setDomainName', '.autotrade.su']);
_gaq.push(['_trackPageview']);

$(function () {
    // P: 5058 H: 123169
    try {
        $('.top-sub-menu a.filial').on(
            'click'
            , function (e) {
                $.post(
                    '/login/?m=getFilialModal'
                    , function (data) {
                        $('body')
                            .append(data);
                        $('#getFilialModal')
                            .modal()
                            .on('shown.bs.modal', function (e) {
                                $(this).find('.modal-dialog')
                                    .css(
                                        'top'
                                        , $(window).height() / 2 - $(this).find('.modal-dialog').height() / 2
                                    );
                            })
                            .on('hidden.bs.modal', function (e) {
                                $('#getFilialModal')
                                    .remove();
                                // Больше не выводим модальное окно автоматически
                                $.post('/login/?m=isConfirmedFilialGuest');
                            });
                        // Смена филиала гостя
                        $('#getFilialModal .modal-body .list-filial-modal p a').on(
                            'click'
                            , function (e) {
                                $.post(
                                    '/login/?m=setFilialGuest'
                                    , {
                                        filial: $(this).text()
                                    }
                                    , function (data) {
                                        location
                                            .reload();
                                    }
                                );
                            }
                        );
                    }
                );
            }
        );
    } catch (e) {
        ;
    }
    // Вывод модально окна выбора города, если выбрано значение, предложенное системой
    if ($('.top-sub-menu a.filial.confirm-filial').length) {
        $('.top-sub-menu a.filial')
            .click();
    }

    // Костыль с верхним меню
    function offsetTopSubMenu() {
        if ($('.top-sub-menu:visible').length && $('.top-sub-menu').offset().top != $('.topMenu0').height()) {
            $('.top-sub-menu')
                .css(
                    'margin-top'
                    , $('.topMenu0').height()
                );
            $('div.topTable')
                .height(
                    $('.topMenu0').height()
                );
        }
    }

    offsetTopSubMenu();
    $(window).resize(function () {
        offsetTopSubMenu();
    });
    // Костыль для формы авторизации на мобильных устройствах
    if (/Android|webOS|BlackBerry/i.test(navigator.userAgent) && location.pathname == '/search/') {//iPhone|iPad|iPod|
        $('#formLF').css({zoom: "0.8"});
    }

    $('body').on(
        'click',
        [
            '.managerBlockNoAuth a',
            '.managerBlockAuth a',
            '.managerBlockDetails a.managerBlockClose'
        ].join(', '),
        function () {
            if (typeof managerInfo === 'function') {
                if ($(this).hasClass('managerBlockClose')) {
                    localStorage.setItem('question_category', '');
                } else {
                    localStorage.setItem('question_category', 'manager');
                }
                managerInfo();
            }
        }
    );

    // TODO: обр. 381106
    try {
        if (!$.fn.modal.Constructor.VERSION) {
            $.fn.modal.Constructor.VERSION = '3.3.7';
        }
    } catch (e) {
    }
    $(document)
        .on('click', '[data-toggle="lightbox"]', function (event) {
            event.preventDefault();
            event.stopPropagation();
            try {
                $(this).ekkoLightbox({
                    wrapping: false,
                    onShow: function (e) {
                        if (typeof PI === 'object') {
                            if (typeof PI.getCurrentPanel === 'function') {
                                if (PI.getCurrentPanel().find('input[type=checkbox].hidePriceAndSum:not(:checked)').length) {
                                    PI.getCurrentPanel()
                                        .find('input[type=checkbox].hidePriceAndSum')
                                        .data('flHide', true)
                                        .prop('checked', true)
                                        .trigger('change');
                                }
                            }
                        }
                        if ($('.ekko-lightbox .modal-dialog').length) { // hack
                            $('.ekko-lightbox .modal-dialog')
                                .css('display', 'none');
                        }
                        if ($('.ekko-lightbox .close').length) {
                            $('.ekko-lightbox .modal-header')
                                .prepend(
                                    '<button type="button" class="lightbox-btn-print" aria-label="Print">' +
                                    '<span aria-hidden="true">print</span>' +
                                    '</button>'
                                );
                            $('.ekko-lightbox .modal-header').removeClass('hide');
                            $('.ekko-lightbox .modal-header').addClass("show");
                            $(document).on('click.print', '.ekko-lightbox .lightbox-btn-print', function (e) {
                                $('body')
                                    .append(
                                        '<div class="lightbox-print" style="display: none;">' +
                                        '<style type="text/css">' +
                                        '@media print{*{display:none!important;}html,body,.lightbox-print,.lightbox-print img{display:block!important;position:static!important;}}' +
                                        '</style>' +
                                        '</div>'
                                    );
                                $(this)
                                    .closest('.ekko-lightbox')
                                    .find('.img-fluid')
                                    .clone()
                                    .appendTo('.lightbox-print');
                                try {
                                    window
                                        .print();
                                } catch (e) {
                                    ;
                                }
                                $('.lightbox-print')
                                    .remove();
                            });
                        }
                        $('.ekko-lightbox .modal-dialog')
                            .draggable();
                    },
                    onHide: function () {
                        if (typeof PI === 'object') {
                            if (typeof PI.getCurrentPanel === 'function') {
                                if (PI.getCurrentPanel().find('input[type=checkbox].hidePriceAndSum').length) {
                                    if (PI.getCurrentPanel().find('input[type=checkbox].hidePriceAndSum').data().flHide) {
                                        PI.getCurrentPanel()
                                            .find('input[type=checkbox].hidePriceAndSum')
                                            .data('flHide', false)
                                            .prop('checked', false)
                                            .trigger('change');
                                    }
                                }
                            }
                        }
                    }
                });
            } catch (e) {
                ;
            }
        });

    if ($("a[rel='lightbox']").length) {  // P: 4614 H: 115825
        $("a[rel='lightbox']").colorbox({width: "80%", height: "80%", iframe: true});
    }
    $('#back-top').hide();
    $(window).scroll(function () {
        $(this).scrollTop() > 100 ? $('#back-top').fadeIn() : $('#back-top').fadeOut();
    });
    $('#back-top a').click(function () {
        $('body,html').animate({scrollTop: 0}, 800);
        return false;
    });

    /*var ga = document.createElement('script');
    ga.type = 'text/javascript';
    ga.async = true;
    ga.src = ('https:' == document.location.protocol ? 'https://ssl' : '//www') + '.google-analytics.com/ga.js';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ga, s);*/

    $('#adminMenu').click(function () {
        toggleAdminMenu();
    });

    $('#adminSwitcher').click(function () {
        if ($.trim($('#adminMenu').html()) === '') {
            $('#adminMenu')
                .html('<div style="text-align: left;" id="loaderAdmMenu"><img alt="" src="/design/img/ajax-loader.gif" style="float:left;"><p style="padding-top:9px">' + i18next.t('Идет загрузка') + '</p></div>')
            $.ajax({
                type: "POST",
                url: "/engine/getAdmMenu.php",
                data: "method=getAdmMenu",
                dataType: "text",
                success: function (data) {
//                console.log(data);
                    $('#adminMenu').html(data).show();
                }
            });
        }
        window.setTimeout(toggleAdminMenu, 100);
        return false;
    });
});

jQuery.fn.center = function () {
    this.css("position", "absolute");
    this.css("top", (($(window).height() - this.outerHeight()) / 2) + $(window).scrollTop() + "px");
    this.css("left", (($(window).width() - this.outerWidth()) / 2) + $(window).scrollLeft() + "px");
    return this;
};

function hideAdminMenu() {
    $('#adminMenu').hide();
    $('*').unbind('click', hideAdminMenu);
}

function bindHideMenu() {
    $('*').bind('click', hideAdminMenu);
}

function toggleAdminMenu() {
    $('#adminMenu').show();
    window.setTimeout(bindHideMenu, 100);
    return false;
}

var colParam_1 = {'maxWidth': 0, 'maxHeight': 0, 'col': 0};
var colParam_2 = {'maxWidth': 0, 'maxHeight': 0, 'col': 0};
var width_Window = 0;

/* н 68059 begin */
$(window).resize(function () {
    if (isMobile.any()) {
        if (window.orientation == 0 || window.orientation == 180) {
            $('html').css('zoom', 'normal');
        }
    }
    var $managerBlockDetails = $("#managerBlockDetails");
    if ($managerBlockDetails.is(":visible")) {
        $managerBlockDetails.hide();
    }
    hideLogotip();
});

var isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
    }
};

$(document).scroll(function () {
    let scrollLeft = -$(document).scrollLeft();
    $('.topMenu0').css({'left': scrollLeft});
    if (i18next.language === 'en') { /*206366 и.12183*/
        $('#KOD_USER').css({'left': scrollLeft});
        hideLogotip();
    }
    if ($('.cookie:visible').length !== 0) { /*206420 и.12356*/
        $('.cookie').css({'left': scrollLeft});
    }
});

var MS = {
    type: 'b24_crm_form_type',
    const: {
        FORM_PRIVACY_POLICY: 'contact-privacy-policy',
        FORM_WARRANTY: 'contact-warranty', /*208827 и.12190*/
        FORM_COPYRIGHT: 'copyright',
        FORM_MANAGER: 'manager',
        FORM_ASSORT_BANNER: 'assort',
        FORM_AGREEMENT: 'agreement',
        FORM_DEFAULT_USER_LIMIT_SEARCH: 'default-user-limit-search'
    },
    getLogin: function () {
        let login = getCookie('login');
        if (process.env.APP_ENV === 'dev') {
            login = getCookie('dev_login');
        }
        return login;
    },
    hideFieldsB24: function () {
        setTimeout(() => {
            $('.b24-form-field-email, .b24-form-field-phone, .b24-form-field-name,' +
                '.b24-form-field-second-name, .b24-form-field-last-name').css('display', 'none');
            $('.b24-form-field-list')
                .find('input.b24-form-control.b24-form-control-not-empty')
                .parent()
                .each(function () {
                    var label = $(this).find('.b24-form-control-label').text().replace(/\t|\n|/g, '').replace("*", '');
                    if (label === "Филиал" || label === "City") {
                        $(this).closest('.b24-form-field-list').css('display', 'none');
                    }
                });
        }, 100);
    },
    mainHideFieldsB24: function () {
        if ((getCookie('sessid') !== undefined) && (MS.getLogin() !== undefined)) {
            MS.hideFieldsB24()
        }
    },
    b24FormInit: function (form) {
        if (!form) {
            return;
        }
        let type = localStorage.getItem(this.type);
        let fields = form.getFields();
        let formId = parseInt(form.identification.id);
        let login = MS.getLogin();
        var agreement = form.agreements[0];

        /** 215508 и.12945
         * Вывод\подмена соглашения для Казахстана
         *      Текст соглашения указан в html-формате сцелью указания ссылки на форму Битрикс24, но посредством замены
         *      значения agreement.options.content.text, текст соглашения, содержащий html-тэги, вставляется как текст,
         *      из-за чего пришлось использовать хранилище для временного сохранения текста соглашения, который перед
         *      открытием соглашения вставляем как html-код
         */
        if (window.location.host.indexOf('.autotrade.kz') !== -1 && (formId === 130 || formId === 143)) {
            $('.b24-form').delegate('.b24-form-control-desc', 'click', function() {
                setTimeout(function () {
                    let text = localStorage.getItem('b24_agreement_text');
                    if (text !== null) {
                        $('.b24-form').find('.b24-window').each(function () {
                            let $block = $(this).find('.b24-window-popup-body > div > div:first-child');
                            if ($block.length !== 0 && !$block.hasClass('filled')) {
                                $block.addClass('filled').html(text);
                            }
                        });
                        localStorage.removeItem('b24_agreement_text');
                    }
                }, 100);
            });

            fetch('/user/?m=_getAgreementById', {
                method: "POST",
                cache: "no-cache",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: 'id=' + window.env.BITRIX_AGREEMENTS_KZ_ID + '&button_caption=' + form.buttonCaption
            }).then(function (response) {
                response.json().then(function(data) {
                    if (data.hasOwnProperty('TEXT')) {
                        // Сохранение текста соглашения
                        agreement.options.content.text = data.TEXT.replace(/<[^>]*>/g, '');
                        localStorage.setItem('b24_agreement_text', data.TEXT);

                        // Подмена текста подписи галки согласия
                        $('.b24-form-field-agreement-link').html(data.LABEL);
                    }
                })
            }).catch(function () {
                console.info('error');
            })
        }
        if (formId === 143) {
            fields.forEach(function (field) {
                if (field.label === "Филиал") {
                    field.items.forEach(function (item) {
                        if (item.label === $('input[id="user_filial"]').val()) {
                            item.selected = true;
                            field.value = item.value;
                        }
                    });
                }
            });

            MS.mainHideFieldsB24();
        }

        switch (type) {
            case this.const.FORM_PRIVACY_POLICY:
                if (formId === 161) {
                    fields.forEach(function (field) {
                        field.items.forEach(function (item) {
                            if (
                                item.label === $('input[id="user_filial"]').val()
                                || item.label === 'Other questions'
                                || item.label === 'Other'
                            ) {
                                item.selected = true;
                                field.value = item.value;
                            }
                        });
                    });
                }
                break;
            case this.const.FORM_WARRANTY: /*208827 и.12190*/
                if (formId === 161) {
                    fields.forEach(function (field) {
                        if (field.label === 'Category') {
                            field.items.forEach(function (item) {
                                if (item.label === 'Contact manager') {
                                    item.selected = true;
                                    field.value = item.value;
                                }
                            });
                        }
                        if (field.label === 'Subcategory contact manager') {
                            field.items.forEach(function (item) {
                                if (item.label === 'Return') {
                                    item.selected = true;
                                    field.value = item.value;
                                }
                            });
                        }
                    });
                }
                break;
            case this.const.FORM_COPYRIGHT:
            case this.const.FORM_MANAGER:
            case this.const.FORM_ASSORT_BANNER:
                if (formId === 130) {
                    let category = localStorage.getItem('question_category'),
                        category_label = '',
                        subcategory = '';
                    fields.forEach(function (field) {
                        if (field.label === "Филиал") {
                            field.items.forEach(function (item) {
                                if (item.label === $('input[id="user_filial"]').val()) {
                                    item.selected = true;
                                    field.value = item.value;
                                }
                            });
                        } else if (field.label === "Категория") {
                            switch (category) {
                                case 'manager':
                                    category_label = 'Связаться с менеджером';
                                    break;
                                case 'assort':
                                    category_label = 'Прочие вопросы';
                                    subcategory = 'Предложить новый ассортимент';
                                    break;
                                case 'agreement':
                                    category_label = 'Прочие вопросы';
                                    subcategory = 'Отзыв согласия на обработку персональных данных';
                                    break;
                            }

                            if (category_label !== '') {
                                field.items.forEach(function (item) {
                                    if (item.label === category_label) {
                                        item.selected = true;
                                        field.value = item.value;
                                    }
                                });
                            }
                        } else if (subcategory !== '' && field.label === "Подкатегории " + category_label.toLowerCase()) {
                            field.items.forEach(function (item) {
                                if (item.label === subcategory) {
                                    item.selected = true;
                                    field.value = item.value;
                                }
                            });
                        }
                    });
                } else if (formId === 161) {
                    fields.forEach(function (field) {
                        field.items.forEach(function (item) {
                            if (type === MS.const.FORM_MANAGER) {
                                if (
                                    item.label === $('input[id="user_filial"]').val()
                                    || item.label === 'Contact manager'
                                ) {
                                    item.selected = true;
                                    field.value = item.value;
                                }
                            }

                            if (type === MS.const.FORM_ASSORT_BANNER) {
                                if (
                                    item.label === $('input[id="user_filial"]').val()
                                    || item.label === 'Other questions'
                                    || item.label === 'Offer a new stock'
                                ) {
                                    item.selected = true;
                                    field.value = item.value;
                                }
                            }
                        });
                    });
                }
                break;
            case this.const.FORM_DEFAULT_USER_LIMIT_SEARCH:
                if (formId === 130 || formId === 161) {
                    fields.forEach(function (field) {
                        field.items.forEach(function (item) {
                            if (
                                item.label === $('input[id="user_filial"]').val()
                                || item.label === 'Активация профиля'
                                || item.label === 'Profile activation'
                            ) {
                                item.selected = true;
                                field.value = item.value;
                            }
                        });
                    });
                }
                break;
            default:
                return;
        }
        if ((getCookie('sessid') !== undefined) && (login !== undefined)) {
            form.setValues({
                "last-name": $('input[id="last_name_user"]').val(),
                "name": $('input[id="first_name_user"]').val(),
                "second-name": $('input[id="user_second_name"]').val(),
                "email": $('input[id="user_email"]').val(),
                "phone": $('input[id="user_phone"]').val()
            });
        }
    }
}

$(document).ready(function () {
    $(window).resize(); /*206366 и.12183*/

    if (location.pathname === "/feedback/") {
        $('.form-feedback').html(
            '<script data-b24-form="inline/143/doo3mp" data-skip-moving="true">\n' +
            '   (function(w,d,u){\n' +
            '       var s=d.createElement(\'script\');s.async=true;s.src=u+\'?\'+(Date.now()/180000|0);\n' +
            '       var h=d.getElementsByTagName(\'script\')[0];h.parentNode.insertBefore(s,h);\n' +
            '   })(window,document,\'https://b24.autotrade.su/upload/crm/form/loader_143_doo3mp.js\');\n' +
            '</script>'
        );
    }

    $('a.copyright').before(getB24FeedBack());

    window.addEventListener('b24:form:init', (event) => {
        console.info('b24:form:init'); // TODO
        if (typeof MS === 'object') {
            setTimeout(function () {
                MS.b24FormInit(event.detail.object);
            }, 50);
        }

        function setCaptchaLang(recaptchaContainer, lang) {
            lang = lang || "en";
            // 1. Search for the ReCaptcha iframe
            var iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');
            // 2. Retrieve the current language
            var currentLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();
            // 3. Verify if the language that you want to set is different to the current one
            if (currentLang !== lang) {
                // 4. If it is, change it
                iframeGoogleCaptcha.setAttribute(
                    "src",
                    iframeGoogleCaptcha.getAttribute("src").replace(
                        /hl=(.*?)&/,
                        'hl=' + lang + '&'
                    )
                );
            }
        }

        function hideBlockB24FormRecaptcha(isHide = true) {
            var blocksCaptcha = document.getElementsByClassName("b24-form-recaptcha");
            if (blocksCaptcha.length) {
                for (var i in blocksCaptcha) {
                    if (blocksCaptcha.hasOwnProperty(i)) {
                        if (isHide) {
                            blocksCaptcha[i].classList.add('hide')
                        } else {
                            blocksCaptcha[i].classList.remove('hide')
                        }
                    }
                }
            }
        }

        if (i18next.language !== 'ru') {
            setTimeout(() => {
                hideBlockB24FormRecaptcha();
            }, 100)

            setTimeout(function () {
                var iframes = document.getElementsByClassName("grecaptcha-badge");
                if (iframes.length) {
                    for (var i in iframes) {
                        if (iframes.hasOwnProperty(i)) {
                            setCaptchaLang(iframes[i], "en")
                        }
                    }
                }
            }, 3000);

            setTimeout(() => {
                hideBlockB24FormRecaptcha(false);
            }, 3200)
        }
    });

    $('body')
        .on('click.captcha-refresh', '.captcha a.btn-refresh', function () {
            let $this = $(this);
            let $captcha = $this.closest('.captcha');
            $this.hide();
            $captcha.find('.loader').show();
            $.post("/user/index.php?m=_createAndCheckCaptcha", {}, function () {
                $('#input-captcha').val('');
                $captcha.find('.loader').hide();
                $this.show();
                // Время добавляем для обновления изображения, в противном случае изображение не будет обновлено, а код обновится
                $captcha.find('img.img-captcha').attr('src', '/user/?m=_createAndCheckCaptcha&' + (Math.round(new Date().getTime() / 1000.0)));
            });
        })
        .on('click.copyright', 'a.copyright', function (e) {
            let type = $(e.currentTarget).data('category');
            if (type === 'foot') {
                localStorage.setItem(MS.type, MS.const.FORM_COPYRIGHT);
            } else if (type === 'manager') {
                localStorage.setItem(MS.type, MS.const.FORM_MANAGER);
            } else if (type === 'assort') {
                localStorage.setItem(MS.type, MS.const.FORM_ASSORT_BANNER);
            } else if (type === 'agreement') {
                localStorage.setItem(MS.type, MS.const.FORM_AGREEMENT);
            }
            MS.mainHideFieldsB24();
        })
        .on('click.contact-warranty', 'a.contact-warranty', function () { /*208827 и.12190*/
            localStorage.setItem(MS.type, MS.const.FORM_WARRANTY);
            MS.mainHideFieldsB24();
        })
        .on('click', 'a.contact-privacy-policy, a.contact-cookie-policy', function () { /*206420 и.12356*/
            localStorage.setItem(MS.type, MS.const.FORM_PRIVACY_POLICY);
            MS.mainHideFieldsB24();
        })
        .on('click.default-user-limit-search', 'a.default-user-limit-search', function () {
            localStorage.setItem(MS.type, MS.const.FORM_DEFAULT_USER_LIMIT_SEARCH);
            MS.mainHideFieldsB24();
        })
    ;

    width_Window = $(window).width();
    // при загрузке документа присвом блоку со списком менеджеров 2-юу ширину
    $("#managerBlockDetails").show();
    $("#managerBlockDetails").width(getWidthForManager(1));
    colParam_1 = {'maxWidth': $("#managerBlockDetails").outerWidth(true), 'col': 1};
    $("#managerBlockDetails").width(getWidthForManager(2));
    $("#managerBlockDetails").css({"height": "auto"});
    colParam_2 = {'maxWidth': $("#managerBlockDetails").outerWidth(true), 'col': 2};
    $("#managerBlockDetails").css({"height": "auto"});
    colParam_1['maxHeight'] = $("#managerBlockDetails").height();
    colParam_2['maxHeight'] = $("#managerBlockDetails").height();
    $("#managerBlockDetails").hide();
    /*$('html, .topMenu').css('min-width', (withLeftAndRight() + 10) + 'px');
    $('.topMenu0').css('width', 'auto');*/
    sortCartsSelect();
    if ($('#number-of-items-in-cart').hasClass('bg_cart') && $('#price-of-items-in-cart').hasClass('bg_cart')) {
        let cartId = localStorage.getItem('activeCart'),
            cartOption = $('#carts_list option[value=' + cartId + ']');
        if (cartId !== null && cartOption.length && cartId !== 'addCart') {
            getCart(cartId);
            $('#carts_list').val(cartId).change();
        } else {
            localStorage.setItem('activeCart', $('#carts_list option:first').val());
            getCart();
        }
    }
    hideLogotip();
    /* P: 3586 H: 103952 an.gerasimov 31.07.15 */
    $('body').delegate(
        '.panel img, .modal img',
        'click.img-news',
        function () {
            if ($(this).data().src !== undefined) {
                var _html,
                    _modal,
                    _title,
                    _src;
                if ($(this).closest('.panel').length) {
                    _title = $(this).closest('.panel').find('[class *= head] .text-left').text().trim();
                } else {
                    _title = $(this).closest('.modal').find('[class *= head] .text-left').text().trim();
                }
                if ($(this).attr('data-title')) {
                    _title = $(this).attr('data-title');
                }
                _src = $(this).data().src;
                _html = '<div class="modal fade modal-large-img">' +
                    '	<div class="modal-dialog">' +
                    '		<div class="modal-content">' +
                    '			<div class="modal-header">' +
                    '				<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
                    '				<h4 class="modal-title">' + _title + '</h4>' +
                    '			</div>' +
                    '			<div class="modal-body text-center">' +
                    '				<p style="margin: 0"><img alt="" src="' + _src + '" style="max-width: 500px;" /></p>' +
                    '			</div>' +
                    '			<div class="modal-footer" style="margin: 0">' +

                    /* P: 3628 H: 104431 an.gerasimov 24.08.15 */
                    // '				<button type="button" class="btn btn-primary" data-dismiss="modal">Закрыть</button>' +
                    '               <div class="row" style="margin: 0 -10px 0 -10px">' +
                    '                   <div class="col-sm-9 text-left">' +
                    '                       <strong>' + i18next.t('Есть предложения по расширению ассортимента?') + ' ' +
                    ($('#footer').find('.copyright').length > 0 ?
                        getB24FeedBack() +
                        '<a data-category="assort" class="link_at copyright">' + i18next.t('Напишите нам!') + '</a>' :
                        i18next.t('Напишите нам!') + ' <a class="link_at" href="mailto:help@autotrade.su?subject=' + i18next.t('Предложения по расширению ассортимента') + '">help@autotrade.su</a>') +
                    '</strong>' +
                    '                   </div>' +
                    '                   <div class="col-sm-3 text-right">' +
                    '                       <button type="button" class="btn btn-primary" data-dismiss="modal">' +
                    '                           <span class="glyphicon glyphicon-remove"></span> ' + i18next.t('Закрыть') +
                    '                       </button>' +
                    '                   </div>' +
                    '               </div>' +

                    '			</div>' +
                    '		</div>' +
                    '	</div>' +
                    '</div>';
                $('body').append(_html);
                _modal = $('.modal-large-img');
                _modal
                    .modal('show')
                    .on(
                        'shown.bs.modal',
                        function () {
                            localStorage.setItem('question_category', 'assort');
                            modal_centered($(this));
                        }
                    )
                    .on(
                        'hidden.bs.modal',
                        function () {
                            localStorage.setItem('question_category', '');
                            $(this).remove();
                        }
                    );
            } // END if
        } // END function
    ); // END click.img-news

    $('#carts_list').on('change', function (e) {
        let span = '<span class="glyphicon glyphicon-refresh" style="font-size: 12px;"></span>',
            cartId = $('#carts_list option:selected').val();
        if (cartId !== 'addCart') {
            $('#number-of-items-in-cart').html(span);
            $('#price-of-items-in-cart').html(span);
            getCart(cartId);
            localStorage.setItem('activeCart', cartId);
            if ($('#searchNew').length) {
                cQIB.items = {};
                PI.addItemsInQIB(cartId).then(data => {
                    PI.updateCartDataInResultTable();
                });
            }
        } else {
            e.preventDefault();
            let ModalCart = new ModalNewCart();
            ModalCart.handlerAddCart(e);
        }
    });

    $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
        /* jshint -W016 */
        if (~settings.url.indexOf('dadata.ru') && 200 !== jqxhr.status && 'abort' !== jqxhr.statusText) {
            $.post('/user/?m=writeAjaxDaDataLog', {
                href: location.href,
                url: settings.url,
                data: settings.data,
                statusCode: jqxhr.status,
                statusText: jqxhr.statusText,
                responseText: jqxhr.responseText
            });
        }
    });
    $('.b24_feedback').append();
});


function refreshHeight() {
    /* P: 2440 an.gerasimov 18.09.16 */
    // var maxHeight = $(window).height() - ($('#managerBlockDetails').outerHeight(true) - $('#managerBlockDetails').height()) - ($('#managerTablesInfo').outerHeight(true) - $('#managerTablesInfo').height()) - $('.managerBlockAuth').outerHeight(true) - $('#managerCommentWork').outerHeight(true) - ( 6 );
    // maxHeight = 175;
    // $('#managerTablesInfo').css({"max-height":maxHeight,"overflow-y":"auto"});
}

function checkHeight(winHeight) {
    if (winHeight - $("#managerBlockDetails").height() < 6) { // проверка вписывается ли блок
        refreshHeight();
    } else if ((winHeight - $("#managerBlockDetails").height() != winHeight - (colParam_2['maxHeight'] + 20))
        || winHeight - $("#managerBlockDetails").height() > 6 && winHeight - (colParam_2['maxHeight'] + 20) < 6) {
        refreshHeight();
    } else {
        $('#managerTablesInfo').css({"max-height": ""});
    }
}

function refreshWidthAndHeight() {
    /*206366 и.12183*/
    let height = parseInt($('.topMenu0').height())/* + parseInt($('#KOD_USER').height())*/;
    if (!isNaN(height)) {
        $('#managerBlockDetails').css('top', height + 1);
    }

    // определяем размеры окна
    var winWidth = $(window).width();
    var winHeight = $(window).height();

    if (winWidth - colParam_2['maxWidth'] > 35 * 2) { // 35 - отступы слева и справа
        $("#managerBlockDetails").width(getWidthForManager(2));
        checkHeight(winHeight); // проверяем высоту блока
        var blockLeft = $("#managerBlockDetails").offset().left;
        var blockTop = $("#managerBlockDetails").offset().top;
        return;
    } else if (winWidth - colParam_1['maxWidth'] > 35 * 2 || winWidth < colParam_2['maxWidth']) { // 35 - отступы слева и справа
        $("#managerBlockDetails").width(getWidthForManager(1));
        checkHeight(winHeight);
        var blockLeft = $("#managerBlockDetails").offset().left;
        var blockTop = $("#managerBlockDetails").offset().top;
        return;
    } else { // пересчитать высоту блока
        checkHeight(winHeight);
    }
}

function managerInfo() {
    var $managerInfo = $(".managerInfo");
    var $managerBlockDetails = $("#managerBlockDetails");
    var $managerBlockClose = $("a.managerBlockClose");
    var $managerCommentWork = $("#managerCommentWork");

    $($managerInfo).each(function () {
        $(this).show();
    });

    if ($managerBlockDetails.is(":hidden")) {
        $managerBlockDetails.show();
        refreshWidthAndHeight();
        $($managerInfo).each(function () {
            $(this).css({
                "display": "inline-block"
            });
        });
        $managerBlockClose.show();
        $managerCommentWork.show();

    } else {
        $managerBlockDetails.hide();
    }
}

function getWidthForManager(col) {
    var arrMaxWidth = new Array();
    var maxWidth = 0;
    var arrWidth = new Array();
    $("#managerInfo > table").each(function () {
        arrMaxWidth.push($(this).width());
    });

    var i = 0;
    for (var id in arrMaxWidth) {
        if (arrWidth[i] == undefined) arrWidth[i] = 0;
        arrWidth[i] += arrMaxWidth[id];
        if ((parseInt(id) + 1) % col == 0) i++;
    }
    for (var j in arrWidth) {
        if (arrWidth[j] > maxWidth) maxWidth = arrWidth[j];
    }
    return maxWidth + 30;
}

/* н 68059 end */

/* P: 2419 H: 105741 an.gerasimov 21.10.15 */

/* Функция для подсчета количества и суммы товаров в корзине.
Информация отображается в верхней панели, значок корзины.
Не учитывается стоимость товаров с неактуальной информацией (проценка). */
function getCart(cartId = 'default') {

    var request;			// Для $.post

    request = {
        auth_key: ':auth_key',
        method: 'getBasket',
        params: {
            cart_id: cartId,
            only_amount_and_prices: true
        }
    }

    try {
        hideLogotip();
        $.post(
            '/api_proxy.php',
            'data=' + JSON.stringify(request),
            function (data) {

                /* P: 3490 H: 106962 an.gerasimov 06.12.15 */
                if (typeof data != 'object' || data == null) return false;

                var html_currency;

                if (data !== undefined) {

                    if (data.code == 2000) {
                        $('#number-of-items-in-cart, #price-of-items-in-cart').html(0);
                    } else {
                        var $numberOfItemsInCart = $('#number-of-items-in-cart');
                        var $priceOfItemsInCart = $('#price-of-items-in-cart');
                        if (i18next.language === 'en') { /*206366 и.12183*/
                            $numberOfItemsInCart.html(data.amount + '<span>&nbsp;' + i18next.t('шт') + '</span>');
                        } else {
                            $numberOfItemsInCart.html(data.amount);
                        }
                        $priceOfItemsInCart.html(data.price);

                        if (data.currency !== undefined) {
                            html_currency = '<span style="font-weight:normal;">&nbsp;' + data.currency + '</span>';
                        } else {
                            html_currency = '<span style="font-weight:normal;"></span>';
                        }
                        $priceOfItemsInCart.append(html_currency);

                        window.markupSwitchShowHidePrice();
                    }

                    $('.cart, .cart-icon').on( /*206366 и.12183*/
                        'click',
                        function () {
                            window.location.href = '/cart/';
                        }
                    );

                    hideLogotip();
                }

            }
        );
    } catch (e) {
        return;
    }
}

function hideLogotip() {
    if (window.i18next.language === 'en') { /*206366 и.12183*/
        if ($(window).width() < 1600) {
            $('#logotip').find('img').attr('src', '/design/img/new/logo_small.png');
        } else {
            $('#logotip').find('img').attr('src', '/design/img/new/logo_big.png');
        }
    } else {
        if ($('.topMenu0').height() > parseInt($('.topMenu0').css('min-height')) * 1.5) {
            $('#logotip').hide();
            $('.ny_logo').hide(); // H: 115087
            $('.ny_snowflakes').hide(); // H: 115087
            $('.topMenu').css({'min-width': '0px'});
        } else {
            var minWidthWindow = withLeftAndRight();
            if ($(window).width() > minWidthWindow) {
                $('#logotip').show();
                $('.ny_logo').show(); // H: 115087
                $('.ny_snowflakes').show(); // H: 115087
            }
        }
    }
    $('body').css('padding-top', $('.topMenu0').outerHeight()) /*206366 и.12183*/
}

function withLeftAndRight() {
    var tmLeft = $('.tmLeft');
    var tmRight = $('.tmRight');
    var childsL = $('.tmLeft').children();
    var childsR = $('.tmRight').children();
    var withLeft = 0;
    var withRight = 0;
    for (var i = 0; i < childsL.length; i++) {
        withLeft += $(childsL[i]).outerWidth(true);
    }
    for (var i = 0; i < childsR.length; i++) {
        if ($(childsR[i]).is('#managerBlockDetails')) continue;
        withRight += $(childsR[i]).outerWidth(true);
    }
    return withLeft + withRight;
}

/* P: 3586 H: 103952 an.gerasimov 31.07.15 */

// Выравнивание модального окна по центру
function modal_centered(_modal) {
    var top = $(window).height() / 2 - _modal.find('.modal-dialog').height() / 2;
    //_modal.find('.modal-content').css('top', top);
    _modal.find('.modal-dialog').css('margin-top', (top <= 0 ? 30 : top) + 'px'); /*166982 и.9108*/
} // END modal_centered

/*if (isMobile.any()) {
    if (document.addEventListener) {
        window.addEventListener('pageshow', function (event) {
                if (event.persisted || window.performance && window.performance.navigation.type == 2) {
                    console.info('Страница загружена из кэша, перезагружаем!');
                    location.reload();
                }
            },
            false);

        window.addEventListener('pagehide', function (event) {
                if (event.persisted || window.performance && window.performance.navigation.type == 2) {
                    console.info('Страница загружена из кэша, перезагружаем!h');
                    location.reload();
                }
            },
            false);
    }
}*/

/**
 * Генерация HTML кода для просмотра списка картинок
 * @param params
 * @returns {string}
 */
function makeLightbox(params) {
    if (typeof params !== 'object') {
        params = {};
    }
    if (params.url === undefined) {
        return;
    }
    if (params.gallery === undefined) {
        return;
    }
    if (typeof params.gallery === 'string') {
        params.gallery = Date.now() + '|' + params.gallery;
    }
    if (params.title === undefined) {
        return;
    }
    var html,
        attrs,
        img,
        lightbox,
        returned = [];
    for (var column in params.url) {
        attrs = {
            'data-toggle': 'lightbox',
            'data-gallery': params.gallery,
            'data-title': params.title
        };
        if (params.url.length > 1) {
            attrs['data-footer'] = $('<div/>', {
                class: 'gallery-amount',
                html: $('<span/>', {
                    class: 'gallery-column',
                    text: +column + 1
                })
                    .prepend(i18next.t('Изображение') + ' ')
                    .append(' ' + i18next.t('из') + ' ' + params.url.length)
            })
                .prop('outerHTML');
        }
        if (column > 0) {
            html = '<div/>';
            attrs['data-remote'] = params.url[column];
            lightbox = $(html, attrs);
        } else {
            html = '<a/>';
            if (false === params.isLink) {
                html = '<div/>';
            }
            attrs['href'] = params.url[column];
            let srcImg = i18next.language === 'en' ? '/design/img/new/icons/photo.png' : '/design/img/camera.png'; /*206366 и.12183*/
            if (typeof params.type !== 'undefined' && params.type === 'subcategory-title' && i18next.language === 'en') { /*206366 и.12183*/
                srcImg = '/design/img/new/icons/photo_white.png';
            }
            img = $('<img/>', {
                src: srcImg,
                width: 24,
                height: 24,
                border: 0
            });
            lightbox = $(html, attrs)
                .append(img);
        }
        returned.push(lightbox.prop('outerHTML'));
    }
    return returned.join('');
}

// Наименование полей\сообщений для механизма включения отправки сообщений через Viber
var viber = {
    config: {},
    text: {}
};
$(function () {
    $('input.config:hidden').each(function () {
        viber.config[this.name] = this.value;
    });
    var profile = i18next.t('Сообщение с кодом подтверждения отправлено на номер') + ' <strong>:code:phone</strong> ' +
        i18next.t('от отправителя') + ` <strong>${'en' === i18next.language ? 'PAVELAUTO' : 'AUTOTRADE'}</strong>. ` +
        i18next.t('Введите этот код в поле') + ' <nobr><strong>&laquo;' +
        i18next.t('Код подтверждения') + '&raquo;</strong></nobr> ' + i18next.t('и нажмите') + ' <strong>&laquo;' +
        i18next.t('Подтвердить') + '&raquo;</strong>. ' +
        i18next.t('Если сообщение не пришло, пожалуйста, повторно нажмите') + ' <nobr><strong>&laquo;' +
        i18next.t('Получить код') + '&raquo;</strong>.</nobr><br/>';
    var keyViberAndSMS = 'Viber/SMS';
    viber[keyViberAndSMS] = {
        title: keyViberAndSMS,
        notice: i18next.t('Если Ваш телефон зарегистрирован в мессенджере Viber, все сообщения с сайта теперь будут отправляться в мессенджер.') +
            ' ' +
            i18next.t('Сообщение будет приходить в раздел бизнес-чаты от отправителя') + ` <strong>${'en' === i18next.language ? 'PAVELAUTO' : 'AUTOTRADE'}</strong>. ` +
            i18next.t('Если же Ваш телефон не зарегистрирован в Viber, то Вам будут приходить стандартные sms-сообщения.') +
            ' ' +
            i18next.t('Для получения сообщений в Viber на телефоне должен быть подключен WI-FI или мобильный интернет.') +
            ' ',
        profile: profile + '<strong>' + i18next.t('Внимание!') + '</strong> ' +
            i18next.t('Для подтверждения действителен только последний отправленный код!') + ' ' +
            i18next.t('Если Ваш телефон зарегистрирован в мессенджере Viber, все сообщения с сайта теперь будут отправляться в мессенджер.') +
            ' ' +
            i18next.t('Сообщение будет приходить в раздел бизнес-чаты от отправителя') +
            ` <strong>${'en' === i18next.language ? 'PAVELAUTO' : 'AUTOTRADE'}</strong>. ` +
            i18next.t('Если же Ваш телефон не зарегистрирован в Viber, то Вам будут приходить стандартные sms-сообщения.') +
            ' ' +
            i18next.t('Для получения сообщений в Viber на телефоне должен быть подключен WI-FI или мобильный интернет.') + ' ' +
            i18next.t('Если сообщение не пришло в течение 5 минут, проверьте правильность указания номера телефона и подключение к интернету.') /*176396 и.10163*/
    };
    var keySMS = 'SMS';
    viber[keySMS] = {
        title: keySMS,
        notice: '',
        profile: profile + '<strong>' + i18next.t('Внимание!') +
            '</strong> ' +
            i18next.t('Для подтверждения действителен только последний отправленный код!') + ' ' +
            i18next.t('Если сообщение не пришло в течение 5 минут, проверьте правильность указания номера телефона и возможность получения SMS-сообщений.') /*176396 и.10163*/
    };
    if ('ru' === i18next.language) {
        viber[keySMS].profile = `<p>Сообщение с кодом подтверждения отправлено на номер <strong>:code:phone</strong> от отправителя
    <strong>AUTOTRADE</strong> одним из каналов доставки:</p>
<ol>
    <li>Telegram, если вы подписаны на чат бот;</li>
    <li>VK-мессенджер, если аккаунт активен;</li>
    <li>Мессенджер Viber, в разделе Бизнес-чаты;</li>
    <li>WhatsApp.</li>
</ol>
<p>Введите этот код в поле
    <nobr><strong>&laquo;Код подтверждения&raquo;</strong></nobr>
    и нажмите <strong>&laquo;Подтвердить&raquo;</strong>. Если сообщение не пришло, пожалуйста, повторно нажмите
    <nobr><strong>&laquo;Получить код&raquo;</strong>.</nobr>
    <br/><strong>Внимание!</strong> Для подтверждения действителен только последний отправленный код! Если сообщение не
    пришло в течение 5 минут, проверьте правильность указания номера телефона и возможность получения сообщений в
    указанных мессенджерах.
</p>`;
        if (window.location.href.indexOf('/user/?m=reg') > 0) {
            viber[keySMS].profile = `<p>Сообщение с кодом подтверждения отправлено на номер <strong>:code:phone</strong> от отправителя
    <strong>AUTOTRADE</strong> одним из каналов доставки:</p>
<ol>
    <li>Telegram, если вы подписаны на чат бот;</li>
    <li>VK-мессенджер, если аккаунт активен;</li>
    <li>Мессенджер Viber, в разделе Бизнес-чаты;</li>
    <li>WhatsApp.</li>
</ol>
<p>Введите этот код в поле
    <nobr><strong>&laquo;Код подтверждения&raquo;</strong></nobr>
    и нажмите <strong>&laquo;Подтвердить&raquo;</strong>.</nobr>
    <br/><strong>Внимание!</strong> Для подтверждения действителен только последний отправленный код! Если сообщение не
    пришло в течение 5 минут, проверьте правильность указания номера телефона и возможность получения сообщений в
    указанных мессенджерах или позвоните с указанного Вами номера на <strong>+79642304210</strong> и мы продиктуем код.
</p>`;
        }
    }
    viber.text = 1 == viber.config.use_viber && 1 == viber.config.show_viber_info ? viber[keyViberAndSMS] : viber[keySMS];
    viber['default'] = {
        title: i18next.t('Сообщение'),
        notice: i18next.t('Настройки подписки можно изменить в') +
            ' <a class="solid-blue-link" href="/user/?m=profile&tab=userProfile">' + i18next.t('Личном кабинете') + '</a>.',
        profile: profile + '<strong>' + i18next.t('Внимание!') +
            '</strong> ' + i18next.t('Для подтверждения действителен только последний отправленный код!') + ' ' +
            i18next.t('Если сообщение не пришло в течение 5 минут, проверьте правильность указания номера телефона и возможность') +
            ' ' +
            i18next.t('получения SMS-сообщений на него.')
    };
    var hintToSendMessage = $('input#hintToSendMessage:hidden').val();
    if (hintToSendMessage && hintToSendMessage.trim().length) {
        var tmpHintToSendMessage = ' <span class="icon-question" data-container="body" data-toggle="popover" ' +
            'data-placement="bottom" data-trigger="hover" data-content="%s" data-html="true" ' +
            'style="margin-bottom: -5px;"></span>';
        var htmlHintToSendMessage = sprintf(tmpHintToSendMessage, hintToSendMessage);
        viber['default'].title += htmlHintToSendMessage;
    }
});

var getSettingsStorages = {
    debug: false,
    allowed_storages: [],
    storages: {},
    init: function (callback) {
        var _this = this;
        $.when(_this.getAllowedStoragesList(), _this.getStoragesList()).done(function () {
            callback && callback();
        });
    },
    getAllowedStoragesList: function (callback) {
        var _this = this;
        _this.allowed_storages = [];
        var rq = {
            method: 'getAllowedStoragesList'
        };
        return $.post('/api_proxy.php', 'data=' + JSON.stringify(rq), function (data) {
            if (_this.debug) {
                console.info('getAllowedStoragesList', 'data', data);
            }
            if (data) {
                var column = 0;
                $.each(data, function () {
                    _this.allowed_storages[column++] = this;
                });
                if (_this.debug) {
                    console.info('getAllowedStoragesList', 'allowed_storages', _this.allowed_storages);
                }
                callback && callback();
            }
        });
    },
    getStoragesList: function (callback) {
        var _this = this;
        _this.storages = {};
        var rq = {
            method: 'getStoragesList'
        };
        return $.post('/api_proxy.php', 'data=' + JSON.stringify(rq), function (data) {
            if (_this.debug) {
                console.info('getStoragesList', 'data', data);
            }
            if (data) {
                $.each(data, function () {
                    _this.storages[this.id] = this;
                });
                if (_this.debug) {
                    console.info('getStoragesList', 'storages', _this.storages);
                }
                callback && callback();
            }
        });
    }
};

/**
 * Функционал для баннера с прайсами уценки
 * [н.0000173780]
 */
$('.top-row').on('click', 'button[data-dismiss=modal]', function (e) {
    $("#modalConfirm").modal('hide');
    $("#modalConfirm").remove();
    $(".modal-backdrop").remove();
    $('body').removeClass('modal-open');
    $('body').css("padding-right", "");
});

$('#confirm_block').on('click', 'button[name="prisedownload"]', function (e) {
    var link = document.createElement('a');
    link.setAttribute('href', $(this).attr("data-id"));
    link.setAttribute('download', 'discount_prise_Autotrade.xlsx');
    link.click();
    return false;
});

function MyConfirm(message, action) {
    var id = $('#priseurl').attr("data-url");
    $('#confirm_block').append(
        '<div tabindex="-1" id="modalConfirm" class="modal fade">' +
        '<div class="modal-dialog" style="height: 137px; width: 470px;">' +
        '<div class="modal-content">' +
        '<div class="modal-body text-center" style="text-align: center; padding-bottom: 0;">' +
        '<p style="font-size: 14px;"><b>' + message + '</b></p>' +
        '</div>' +
        '<div class="modal-footer text-center">' +
        '<button type="button" class="btn btn-default" data-dismiss="modal" id="cancelBtn" data-id="' + id + '"><span class="glyphicon glyphicon-remove"></span> ' + i18next.t('Отмена') + '</button>' +
        '<button type="button" class="btn btn-primary" data-dismiss="modal" id="confirmBtn" name="' + action + '" data-id="' + id + '"><span class="glyphicon glyphicon-ok" style="top: 2px;"></span> ' + i18next.t('Да') + '</button>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>'
    );
    var middleScreen = $(window).height() / 2;
    var heightContainer = $('#modalConfirm .modal-dialog').height() / 2;
    var marginTop = middleScreen - heightContainer;
    if (marginTop > 0) {
        $('#modalConfirm .modal-dialog').css('margin-top', marginTop);
    }
    $('#modalConfirm').modal({
        backdrop: 'static',
        keyboard: false
    });
    $('#modalConfirm').modal('show');
}

function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function getB24FeedBack(name) {
    let b24form = '<script data-b24-form="click/130/lhhqxk" data-skip-moving="true">\n' +
        '   (function(w,d,u){\n' +
        '       var s=d.createElement(\'script\');s.async=true;s.src=u+\'?\'+(Date.now()/180000|0);\n' +
        '       var h=d.getElementsByTagName(\'script\')[0];h.parentNode.insertBefore(s,h);\n' +
        '   })(window,document,\'https://b24.autotrade.su/upload/crm/form/loader_130_lhhqxk.js\');\n' +
        '</script>';
    if (i18next.language !== 'ru') {
        b24form = '<script data-b24-form="click/161/xo04mz" data-skip-moving="true">(function(w,d,u){var\n' +
            's=d.createElement(\'script\');s.async=true;s.src=u+\'?\'+(Date.now()/180000|0);var\n' +
            'h=d.getElementsByTagName(\'script\')[0];h.parentNode.insertBefore(s,h);})(window,document,\n' +
            '\'https://b24.autotrade.su/upload/crm/form/loader_161_xo04mz.js\');</script>\n';
    }

    return b24form;
}

function sortCartsSelect() {
    let cartsNumbering = JSON.parse(localStorage.getItem('cartsNumbering')) ?? {},
        cartSelect = $('#carts_list'),
        carts = cartSelect.children('option');
    if (cartsNumbering.length > 0) {
        for (let i = cartsNumbering.length - 1; i >= 0; i--) {
            for (let j = carts.length - 1; j >= 0; j--) {
                if (+carts[j].value === cartsNumbering[i]) {
                    cartSelect.children('option[value="' + cartsNumbering[i] + '"]').remove();
                    cartSelect.prepend(carts[j]);
                    break;
                }
            }
        }
    }
}

var ConfirmedContacts = { /*175186 и.9547*/
    checkConfirmed: function (system) {
        var self = this;
        $.post('/user/?m=getConfirmed', function (data) {
            try {
                data = JSON.parse(data);
            } catch (e) {
                data = {};
            }
            var confirm = {
                login: data.login_confirmed,
                phone: data.phone_fed_confirmed
            };
            if (confirm.login == 0 || confirm.phone == 0) {
                self.showModalConfirmed(confirm, system);
            }
        });
    },
    showModalConfirmed: function (confirm, system) {
        var text = '';
        switch (system) {
            case 'basket':
                text = ' ' + i18next.t('с корзиной');
                break;
            case 'search':
                text = ' ' + i18next.t('с системой поиска');
                break;
        }
        var contact = [];
        if (confirm.login == 0) {
            contact.push(i18next.t('Логин').toLowerCase());
        }
        if (confirm.phone == 0) {
            contact.push(i18next.t('основной номер мобильного телефона'));
        }

        var html = '' +
            '<div class="modal fade" tabindex="-1" role="dialog" id="confirmed_modal">\n' +
            '	<div class="modal-dialog">\n' +
            '		<div class="modal-content">\n' +
            '			<div class="modal-header">\n' +
            '				<h4 class="modal-title">' + i18next.t('Требуется подтверждение персональных данных') + '</h4>\n' +
            '			</div>\n' +
            '			<div class="modal-body text-justify">\n' +
            '				<p>' + i18next.t('Для продолжения работы') + text + ' ' + i18next.t('подтвердите') + ' ' + contact.join(' ' + i18next.t('и') + ' ') + ' ' + i18next.t('в профиле.') + '</p>\n' +
            '			</div>\n' +
            '			<div class="modal-footer">\n' +
            '				<a class="btn btn-primary" href="/user/?m=profile&tab=userProfile" role="button">\n' +
            '					<span class="glyphicon glyphicon-user" aria-hidden="true"></span> ' + i18next.t('Перейти в профиль') + '\n' +
            '				</a>\n' +
            '			</div>\n' +
            '		</div>\n' +
            '	</div>\n' +
            '</div>\n';
        $('body').append(html);

        var $modal = $('#confirmed_modal');
        $modal
            .on('shown.bs.modal', function () {
                // Прозрачность других модальных окон
                if ($('.modal-backdrop.fade.in').length > 0) {
                    $('.modal:not(#confirmed_modal)').css('opacity', 0.8);
                }
                // Центрирование окна при показе и изменении размеров окна браузера
                modal_centered($modal);
                $(window).on('resize.confirmed', function () {
                    modal_centered($modal);
                });
            })
            .on('hidden.bs.modal', function () {
                // Удаление окна после сокрытия
                $modal.remove();
            })
            .modal({backdrop: 'static', keyboard: false, show: true});
    }
};

var ModalInvoice = {
    show: function (contract_inside_id, items) {
        var self = this;
        self.$preloader = $('<span/>', {
            id: 'search-loader',
            class: 'g-png24 preloader',
            css: {
                left: 0,
                margin: 0
            }
        });
        var body = [];
        body.push(self.$preloader.clone().text(i18next.t('Загрузка данных. Пожалуйста, подождите.')));
        var footer = [];
        footer.push($('<button/>', {
            type: 'button',
            class: 'btn btn-default btn-cancel',
            'data-dismiss': 'modal',
            text: ' ' + i18next.t('Отмена'),
            prepend: $('<span/>', {
                class: 'glyphicon glyphicon-remove',
                'aria-hidden': true
            })
        }));
        self.$modal = $.modal({
            id: 'modalInvoice',
            title: i18next.t('Счет на пополнение баланса'),
            body: body,
            footer: footer,
            minWidth: '595pt'
        });
        self.$body = self.$modal.find('.modal-body');
        self.$footer = self.$modal.find('.modal-footer');
        self.$modal.on('hidden.bs.modal.remove', function (e) {
            if (self.$btnSave) {
                self.$btnSave.popover('hide');
            }
            self.$modal.remove();
            if (self.$invoicePrint) {
                self.$invoicePrint.remove();
            }
        });
        self.$modal.modal('show');
        var url = '/print.php?type=getInvoice';
        var request = {
            contract_inside_id: contract_inside_id,
            items: items
        };
        $.ajax({
            type: 'POST',
            data: request,
            url: url,
            success: function (response, textStatus, jqXHR) {
                self.$invoicePrint = $('<div/>', {
                    class: 'invoice-print',
                    html: response,
                    prepend: '<style type="text/css">@media print{@page{size:A4;margin:1cm}html{min-width:0!important}body>*{display:none!important}.invoice-print{display:block!important;max-width:560pt}}</style>',
                    css: {
                        display: 'none'
                    }
                });
                self.$invoicePrint.appendTo('body');
                self.$body.html(response);
                self.$btnSave = $('<a/>', {
                    href: '#',
                    type: 'button',
                    class: 'btn btn-primary btn-save',
                    text: ' ' + i18next.t('Сохранить'),
                    prepend: $('<span/>', {
                        class: 'glyphicon glyphicon-floppy-save',
                        'aria-hidden': true
                    }),
                    data: {
                        number: jqXHR.getResponseHeader('Number')
                    }
                });
                self.$btnSave.on('click.save', function (e) {
                    if (self.$btnSave.attr('disabled')) {
                        return false;
                    }
                    if ('#' === self.$btnSave.attr('href') && !self.$btnSave.prop('download')) {
                        e.preventDefault();
                        self.$btnSave.popover({
                            container: 'body',
                            toggle: 'popover',
                            placement: 'top',
                            trigger: 'manual',
                            html: true,
                            content: self.$preloader.clone().text(i18next.t('Выполняется сохранение счета. Пожалуйста, подождите.'))
                        });
                        self.$btnSave.popover('show');
                        self.$btnSave.attr('disabled', 'disabled');
                        var url = '/print.php?type=getInvoice';
                        var request = {
                            number: self.$btnSave.data().number,
                            contract_inside_id: contract_inside_id,
                            items: items,
                            is_pdf: true
                        };
                        $.post(url, request, function (response) {
                            self.$btnSave.attr('href', response);
                            self.$btnSave.attr('download', response.split('/').reverse()[0]);
                            self.$btnSave.attr('disabled', null);
                            self.$btnSave.popover('hide');
                            self.$btnSave[0].click();
                        }).fail(function () {
                            self.$btnSave.data('bs.popover').options.content = i18next.t('Что-то пошло не так') + '...';
                            self.$btnSave.popover('show');
                        });
                    }
                });
                self.$footer.append(self.$btnSave);
                self.$btnPrint = $('<button/>', {
                    type: 'button',
                    class: 'btn btn-primary btn-print',
                    text: ' ' + i18next.t('Распечатать'),
                    prepend: $('<span/>', {
                        class: 'glyphicon glyphicon-print',
                        'aria-hidden': true
                    })
                });
                self.$btnPrint.on('click.print', function () {
                    window.print();
                });
                self.$footer.append(self.$btnPrint);
                self.$modal.trigger('shown.bs.modal.top');
            },
            error: function (jqXHR, textStatus, errorThrown) {
                self.$body.html($('<p/>', {
                    text: i18next.t('Что-то пошло не так') + '...'
                }));
            }
        });
    },
    download: ($element, contract_inside_id, items, callback) => {
        let $preloader = $('<span/>', {
            id: 'search-loader',
            class: 'g-png24 preloader',
            css: {
                left: 0,
                margin: 0,
            },
        });
        $element.popover({
            container: 'body',
            toggle: 'popover',
            placement: 'top',
            trigger: 'manual',
            html: true,
            content: $preloader.clone().text(i18next.t('Выполняется сохранение счета. Пожалуйста, подождите.'))
        });
        $element.popover('show');
        $element.attr('disabled', 'disabled');
        let data = {
            contract_inside_id: contract_inside_id,
            items: items,
            is_pdf: true,
        };
        $.post('/print.php?type=getInvoice', data, (data, textStatus, jqXHR) => {
            let $a = $('<a/>', {
                href: data,
                download: data.split('/').reverse()[0],
            });
            $a[0].click();
            $element.attr('disabled', null);
            $element.popover('hide');
            callback && callback();
        }).fail((jqXHR, textStatus, errorThrown) => {
            $element.data('bs.popover').options.content = i18next.t('Что-то пошло не так') + '...';
            $element.popover('show');
        });
    },
    proformaInvoice: function ($element, contract_id, items) {
        let self = this;
        self.$preloader = $('<span/>', {
            id: 'search-loader',
            class: 'g-png24 preloader',
            css: {
                left: 0,
                margin: 0
            }
        });
        let data = {
            contract_id: contract_id,
            items: items,
            email: ''
        };
        let emailRegex = /([^@\s]+)@((?:[-a-zA-Zа-яА-ЯеЁ0-9]+\.)+[a-zA-Zа-яА-ЯеЁ]{2,})/;

        let addOrRemoveClassDanger = function (item, flag) {
            let base = item.parent().parent();
            if (flag === true) {
                let text = i18next.t('Заполнено некорректно');
                base
                    .removeClass('has-success')
                    .addClass('has-error')
                    .find('span.glyphicon')
                    .removeClass('glyphicon-ok')
                    .addClass('glyphicon-exclamation-sign')
                    .attr('title', text)
                    .show();
                base.find('#emailStatus').text(text);
                base.find('.alert.alert-danger').text(text).show();
            } else {
                let text = i18next.t('Заполнено корректно');
                base
                    .removeClass('has-error')
                    .addClass('has-success')
                    .find('span.glyphicon')
                    .removeClass('glyphicon-exclamation-sign')
                    .addClass('glyphicon-ok')
                    .attr('title', text)
                    .show();
                base.find('#emailStatus').text(text);
                base.find('.alert.alert-danger').text(text).hide();
            }
        };

        let $emailUser = $('#user_email').val();
        let userEmail = !PI.empty($emailUser) ? $emailUser : '';

        let body = `
<div class="form-group has-feedback">
    <label class="control-label" for="email">
        ${i18next.t('Пожалуйста, подтвердите адрес электронной почты, на который будет отправлен счет')}
    </label>
    <div class="input-group">
        <span class="input-group-addon">@</span>
        <input id="email" class="form-control" type="email" value="${userEmail}" pattern="([^@\\s]+)@((?:[-a-zA-Zа-яА-ЯеЁ0-9]+\\.)+[a-zA-Zа-яА-ЯеЁ]{2,})" maxlength="256" style="text-align: center;" aria-describedby="emailStatus" />
    </div>
    <span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true" style="display: none;"></span>
    <span id="emailStatus" class="sr-only">${i18next.t('Заполнено корректно')}</span>
    <div class="alert alert-danger" role="alert" style="display: none;"></div>
</div>`;

        let footer = [];
        footer.push($('<button/>', {
            class: 'btn btn-primary send-proforma-invoice',
            type: 'button',
            // 'data-dismiss': 'modal',
            text: ' ' + i18next.t('Ок'),
            prepend: $('<span/>', {
                class: 'glyphicon glyphicon-ok',
                'aria-hidden': true
            })
        }));

        let closeButton = $('<button/>', {
            class: 'btn btn-primary',
            type: 'button',
            'data-dismiss': 'modal',
            text: ' ' + i18next.t('Закрыть'),
            prepend: $('<span/>', {
                class: 'glyphicon glyphicon-remove',
                'aria-hidden': true
            })
        });

        let $modal = $.modal({
            id: 'modalProformaInvoice',
            body: body,
            footer: footer
        });
        let $modalBody = $modal.find('.modal-body');
        let $modelFooter = $modal.find('.modal-footer');
        $modalBody.css('text-align', 'center');
        $modelFooter.css('text-align', 'center');

        $modal.modal('show');

        $modal.on('click.send-proforma-invoice', '.send-proforma-invoice', function () {
            let $email = $('#modalProformaInvoice #email');
            let email = $email.val();
            if (!emailRegex.test(email)) {
                addOrRemoveClassDanger($email, true);
                return false;
            }
            data.email = email;
            $modalBody.empty().append(self.$preloader);
            $.post('/print.php?type=proformaInvoice', data, (result) => {
                $modalBody.empty().append(result);
                $modelFooter.empty().append(closeButton)
            }).fail(() => {
                $modalBody.empty()
                    .append('<strong>' + i18next.t('Произошла ошибка. Повторите попытку позже.') + '</strong>');
            });
        });

        $modal.on('input.modal.proforma-invoice.email', '#email', function () {
            let $this = $(this);
            if (emailRegex.test($this.val())) {
                addOrRemoveClassDanger($this, false);
            } else {
                addOrRemoveClassDanger($this, true);
            }
        });

        $modal.on('shown.bs.modal', function () {
            $modal.find('#email').trigger('input.modal.proforma-invoice.email')
        });
    }
};

var ModalBrandDescription = {
    $preloader: $('<span/>', {
        id: 'search-loader',
        class: 'g-png24 preloader',
        css: {
            left: 0,
            margin: 0
        }
    }),
    show: function (id) {
        var self = this;
        var $footer = $('<button/>', {
            type: 'button',
            class: 'btn btn-primary btn-close',
            'data-dismiss': 'modal',
            text: ' ' + i18next.t('Закрыть'),
            prepend: $('<span/>', {
                class: 'glyphicon glyphicon-remove',
                'aria-hidden': true
            })
        });
        self.$modal = $.modal({
            id: 'modalBrandDescription',
            title: i18next.t('Информация о бренде'),
            body: self.$preloader.clone().text(i18next.t('Загрузка данных. Пожалуйста, подождите.')),
            footer: $footer
        });
        self.$header = self.$modal.find('.modal-header');
        self.$body = self.$modal.find('.modal-body');
        self.$footer = self.$modal.find('.modal-footer');
        self.$modal.modal('show');
        var request = {
            method: 'getBrandDescription',
            params: {
                id: id
            }
        };
        $.post('/api_proxy.php', 'data=' + JSON.stringify(request), function (data) {
            if (0 === data.code) {
                var title = i18next.t('Бренд') + ' <strong>:name</strong>'.replace(':name', data.name);
                self.$header.find('.modal-title').html(title);
                self.$body.css('font-size', 14);
                self.$body.addClass('text-justify');

                var cert = '<table class="table-bordered table-condensed"><tbody>';
                for (var certificate in data.certificate) {
                    if (!data.certificate.hasOwnProperty(certificate)) {
                        continue
                    }
                    var item = data.certificate[certificate];
                    if (
                        $.inArray(parseInt(item.type_certificate), [1, 2])
                        && new Date() < new Date(item.to_date)
                    ) {
                        cert += `<tr>
                        <td>
                            ${!PI.empty(item.photo)
                            ? `<a href="${item.photo}" target="_blank">${item.reg_number}</a>`
                            : item.reg_number}
                        </td>
                        <td>${(new Date(item.from_date)).toLocaleDateString()}</td>
                        <td>${(new Date(item.to_date)).toLocaleDateString()}</td>
                    </tr>`;
                    }
                }
                cert += '</tbody></table>';

                if (window.location.host.indexOf('.autotrade.kz') !== -1) { /*215508 и.12945*/
                    if (!PI.empty(data.link)) {
                        if (data.link.indexOf('sat.autotrade.su') !== -1) {
                            data.link = '';
                        }
                        if (data.link.indexOf('autotrade.su') !== -1) {
                            data.link = data.link.replace('autotrade.su', 'autotrade.kz').replace('moscow', 'almaty');;
                        }
                    }
                    if (!PI.empty(data.warranty_condition) && data.warranty_condition.indexOf('autotrade.su') !== -1) {
                        data.warranty_condition = data.warranty_condition.replace('autotrade.su', 'autotrade.kz').replace('moscow', 'almaty');
                    }
                }

                let body = `<div>
    <h4 class="font-weight-bold">` + i18next.t('Информация о бренде') + `</h4>
    <p>${data.description}</p>
    ${data.warranty_condition ? `<h4 class="font-weight-bold">` + i18next.t('Гарантия от бренда') + `</h4><p>${data.warranty_condition}</p>` : ''}
    ${data.link ? `<h4 class="font-weight-bold">` + i18next.t('Ссылка на сайт производителя') + `</h4><p><a href="${data.link}" style="font-size: inherit;" target="_blank">${data.link}</a></p>` : ''}
    ${process.env.IS_SHOW_BRAND_CERTIFICATE && !PI.empty(data.certificate) ? `<h4 class="font-weight-bold">` + i18next.t('Сертификаты') + `</h4>${cert}` : ''}
</div>`;
                self.$body.html(body);
                if (!PI.empty(data.photo)) {
                    self.$body.prepend($('<img/>', {
                        src: data.photo,
                        alt: data.name,
                        css: {
                            width: 200,
                            height: 200,
                            float: 'right'
                        }
                    }));
                }
                self.$modal.trigger('shown.bs.modal.top');
                var resizeBodyHeight = function () {
                    self.$body.css({
                        height: '',
                        'overflow-y': ''
                    });
                    self.$modal.trigger('shown.bs.modal.top');
                    var windowHeight = $(window).height();
                    var modalContentHeight = self.$modal.find('.modal-content').height();
                    if (windowHeight < modalContentHeight) {
                        self.$body.css({
                            height: windowHeight / 4 * 3,
                            'overflow-y': 'auto'
                        });
                        self.$modal.trigger('shown.bs.modal.top');
                    }
                };
                setTimeout(resizeBodyHeight, 149);
                $(window).on('resize.body.height.modal.brand.description', resizeBodyHeight);
                self.$modal.on('hidden.bs.modal.off.resize.body.height', function () {
                    $(window).off('resize.body.height.modal.brand.description');
                });
            } else if (9 === data.code) {
                self.$body.text(data.message);
            } else {
                self.$body.text(i18next.t('Что-то пошло не так') + '...');
            }
        }).fail(function () {
            self.$body.text(i18next.t('Что-то пошло не так') + '...');
        });
    }
};

var renameTabSearch = { /*114365*/
    params:      'model',
    tech_params: 'part_params'
}

/**
 * Возвращает get-параметр из ссылки
 * @param key Название параметра
 * @returns {string|null}
 */
function getUrlParam(key) {
    /* 137681 5239
     * Добавляем обертку try-catch с целью устранения ошибки <URIError: malformed URI sequence|URIError: URI malformed>
     */
    try {
        var result = new RegExp(key + "=([^&]*)", "i").exec(window.location.search);
        return result && decodeURIComponent(result[1]) || null;
    } catch (e) {
        return null;
    }
}

/**
 * Возвращает все get-параметры из ссылки
 * @returns {{}}
 */
function getAllUrlParam() {
    var args   = {};
    var getVar = null;
    var search = window.location.search.substring(1);
    var hash   = window.location.hash.substring(3);

    /* 108515 2794
     * Т.к. url может содержать решетки (http://example.su/search/?type=tech_params&parts_type_id=####22###),
     * из-за чего его часть с параметрами попадает в хэш (hash), поэтому используем конкатенацию search и hash.
     * Для того, чтобы исключить реальный хэш, не взяв его в качестве параметров поставим условие на существование как
     * левой, так и правой частей между знаком равенства.
     */
    if (search && hash)
        var query = (search+window.location.hash).split("&");
    else
        var query = (search) ? search.split("&") : (hash) ? hash.split("&") : [];

    for( var i = 0; i < query.length; i ++ ) {
        getVar = query[i].split("=");
        if (getVar.length != 2) continue; /*108515 2794*/
        args[getVar[0]] = getVar[1];
    }

    return args;
}

/**
 * Возвращает параметры из ссылки
 * @returns {{}}
 */
function getUrlParams() { /*114365*/
    var params = history.state || getAllUrlParam();
    if (typeof params.type !== 'undefined' && !PI.empty(renameTabSearch)) {
        for (var key in renameTabSearch){
            if (!renameTabSearch.hasOwnProperty(key))
                continue;
            if (renameTabSearch[key] == params.type) {
                params.type = key;
                break;
            }
        }
    }
    return params;
}

window.renameTabSearch = renameTabSearch;
window.getUrlParam = getUrlParam;
window.getAllUrlParam = getAllUrlParam;
window.getUrlParams = getUrlParams;
window.isMobile = isMobile;
window.hideAdminMenu = hideAdminMenu;
window.bindHideMenu = bindHideMenu;
window.toggleAdminMenu = toggleAdminMenu;
window.refreshHeight = refreshHeight;
window.checkHeight = checkHeight;
window.refreshWidthAndHeight = refreshWidthAndHeight;
window.managerInfo = managerInfo;
window.getWidthForManager = getWidthForManager;
window.getCart = getCart;
window.hideLogotip = hideLogotip;
window.withLeftAndRight = withLeftAndRight;
window.modal_centered = modal_centered;
window.makeLightbox = makeLightbox;
window.getSettingsStorages = getSettingsStorages;
window.MyConfirm = MyConfirm;
window.viber = viber;
window.ConfirmedContacts = ConfirmedContacts
window.ModalInvoice = ModalInvoice
window.ModalBrandDescription = ModalBrandDescription
window.MS = MS;
