import $ from "jquery";

export default class ModalNewCart {
    constructor() {
        this.$modal = $('#addCart');
        if (!this.$modal.length) {
            let html = this._getForm();
            this.$modal = $(html);
            this.$modal.appendTo('body');
            this._prepare();
            return true;
        }
    }
    _prepare() {
        this.$content = this.$modal.find('.modal-content');
        this.$body = this.$content.find('.modal-body');
        this.$modal
            .on('hidden.bs.modal', () => {
                let place = this.$modal.attr('data-place'),
                    cartList;
                switch (place){
                    case 'card': cartList = $('#cartListDiv [name="carts_list"]'); break;
                    case 'search': cartList = $('.common-modal select[data-place=search]'); break;
                    default: cartList = $('#carts_list'); break;
                }
                let cartId = cartList.find('option:selected').val();
                if (cartId === 'addCart') {
                    let saveId = localStorage.getItem('activeCart') ?? cartList.find('option:first').val();
                    cartList.val(saveId).change();
                }
                $('[name="newCart"]').val('');
            })
            .on('click', '.btn-save[name="add"]', event => {
                let cartName = this.$content.find('input[name="newCart"]').val(),
                    saveAlert = this.$modal.find('[name=saveCartAlert]>.alert-warning');
                if (this._checkCartName()) {
                    this.createCart(cartName).then(data => {
                        let tabs = $('#cart-tabs-panel');
                        if (data.code === 0) {
                            let place = this.$modal.attr('data-place');
                            this._addOptionToCartList($('#carts_list'), data);
                            switch (place){
                                case 'tab':
                                    let lastTab = tabs.find('.cart-tab:last');
                                    lastTab.after($('<li>', {
                                        class: 'cart-tab',
                                        'data-id': data.cart.id,
                                        'data-name': data.cart.name,
                                        append: $().add(
                                            $('<a/>', {
                                                "data-toggle": "tab",
                                                "aria-expanded": "false",
                                                append: $().add(
                                                    $('<span/>', {
                                                        text: data.cart.name
                                                    })
                                                ).add(
                                                    $('<span/>', {
                                                        class: 'glyphicon glyphicon-pencil'
                                                    })
                                                ).add(
                                                    $('<span/>', {
                                                        class: 'glyphicon glyphicon-remove'
                                                    })
                                                )
                                            })
                                        )
                                    }));
                                    tabs.find('.cart-tab.active').removeClass('active');
                                    tabs.find('li[data-id=' + data.cart.id + ']').tab('show');
                                    break;
                                case 'card':
                                    this._addOptionToCartList($('#cartListDiv [name="carts_list"]'), data);
                                    break;
                                case 'search':
                                    this._addOptionToCartList($('.common-modal select[data-place=search]'), data);
                                    break;
                            }
                            this.$modal.modal('hide');
                        } else {
                            this._showErrSpan(data);
                        }
                        saveAlert.hide();
                    }).catch(error => {
                        this.$modal.modal('hide');
                        saveAlert.hide();
                        console.error(error);
                    });
                }
            })
            .on('click', '.btn-save[name="edit"]', event => {
                let cartName = this.$content.find('input[name="newCart"]').val(),
                    saveAlert = this.$modal.find('[name=saveCartAlert]>.alert-warning'),
                    id = this.$modal.data().id;
                if (this._checkCartName()) {
                    this.updateCart(cartName, id).then(data => {
                        if (data.code === 0) {
                            let cartSelect = $('#carts_list'),
                                cartPanel = $('#cart-tabs-panel');
                            cartSelect.children('option[value=' + id + ']').text(cartName);
                            cartSelect.selectpicker('refresh');
                            cartPanel.find('li[data-id=' + id + ']>a>span:first').text(cartName);
                            cartPanel.find('li[data-id=' + id + ']').data().name = cartName;
                            this.$modal.modal('hide');
                        } else {
                            this._showErrSpan(data);
                        }
                        saveAlert.hide();
                    }).catch(error => {
                        this.$modal.modal('hide');
                        saveAlert.hide();
                        console.error(error);
                    });
                }
            });
    }

    handlerAddCart(event) {
        event.preventDefault();
        this.$modal.find('.modal-title').text(i18next.t('Добавление корзины'));
        this.$modal.find('.btn-save').attr('name', 'add');
        this.$modal.find('input[name="newCart"]').val('');
        this.$modal.attr('data-place', event.currentTarget.getAttribute('data-place'));
        this.$modal.modal('show');
    }

    handlerEditCart(event) {
        event.preventDefault();
        let $tab = $(event.currentTarget).closest('li');
        this.$modal.data().id = $tab.data().id;
        this.$modal.find('.modal-title').text(i18next.t('Редактирование названия корзины'));
        this.$modal.find('.btn-save').attr('name', 'edit');
        this.$modal.find('input[name="newCart"]').val($tab.data().name);
        this.$modal.modal('show');
    }

    async createCart(name) {
        let request = {
                auth_key: ':auth_key',
                method: 'addCart',
                params: {
                    name: name,
                }
            };
        return await $.post(
            '/api_proxy.php',
            'data=' + JSON.stringify(request),
            function (data) {
                if (typeof data !== 'object' || data == null) return false;
                if (data.code === 0 &&  data.cart !== undefined) {
                    return data;
                }
            }
        );
    }

    async updateCart(name, id) {
        let request = {
                auth_key: ':auth_key',
                method: 'updateCart',
                params: {
                    id: id,
                    name: name,
                }
            };
        return await $.post(
            '/api_proxy.php',
            'data=' + JSON.stringify(request),
            function (data) {
                if (typeof data !== 'object' || data == null) return false;
                return data;
            }
        );
    }

    _getForm() {
        return  '<div id="addCart" class="modal fade" style="display: none;z-index: 1050;">' +
        '            <div class="modal-dialog">' +
        '                <div class="modal-content">' +
        '                    <div class="modal-header">' +
        '                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>' +
        '                        <h4 class="modal-title">' + i18next.t('Добавление корзины') + '</h4>' +
        '                    </div>' +
        '                    <div class="modal-body">' +
        '                        <div class="row">' +
        '                           <div class="col-md-12">'+
        '                           <p class="alert alert-warning" style="margin: 0 0 10px 0;">' + i18next.t('Длина наименования не может содержать более 30 символов.') + '</p>' +
        '                               <input name="newCart" type="text" maxlength="30" class="form-control" placeholder="' + i18next.t('Введите название корзины') + '" required>' +
        '                               <div name="saveCartAlert">' +
        '                                   <p class="alert alert-danger" for="">' + i18next.t('Введите название корзины') + '</p>' +
        '                                   <p class="alert alert-warning">' + i18next.t('Идет сохранение корзины') + '...</p>' +
        '                               </div>' +
        '                           </div>' +
        '                       </div>' +
        '                    </div>' +
        '                    <div class="modal-footer">' +
        '                        <button type="button" class="btn btn-default" data-dismiss="modal"><span class="glyphicon glyphicon-remove"></span> ' + i18next.t('Закрыть') + '</button>' +
        '                        <button type="button" class="btn btn-primary btn-save"><span class="glyphicon glyphicon-floppy-disk"></span> ' + i18next.t('Сохранить') + '</button>' +
        '                    </div>' +
        '                </div>' +
        '            </div>' +
        '        </div>';
    }

    _checkCartName() {
        let cartName = this.$content.find('input[name="newCart"]').val();
        if (cartName === '' || cartName === null || cartName === undefined) {
            this.$body.addClass('has-error');
            return false;
        } else {
            let saveAlert = this.$modal.find('[name=saveCartAlert]>.alert-warning');
            this.$body.removeClass('has-error');
            saveAlert.show();
        }
        return true;
    }

    _showErrSpan(data) {
        let errSpan = this.$body.find('[name="errSetCart"]'),
            message = data.error ?? i18next.t("Произошла ошибка, попробуйте позже.");
        if (data.message) {
            if (typeof data.message === 'object') {
                message = Object.values(data.message)[0];
            } else {
                message = data.message;
            }
        }

        if (errSpan.length === 0) {
            this.$body.find('.col-md-12').append(
                $().add($('<p/>', {
                    name: 'errSetCart',
                    class: 'alert alert-danger',
                    css: {
                        display: 'none',
                        margin: '10px 0 0 0'
                    }
                }))
            );
            errSpan = this.$body.find('[name="errSetCart"]');
        }
        errSpan.text(message);
        errSpan.show().fadeOut(10000);
    }

    _addOptionToCartList(cartSelect,data) {
        cartSelect.children('option:last').after($('<option>', {
            value: data.cart.id,
            text: data.cart.name
        }));
        cartSelect.val(data.cart.id).change();
        cartSelect.selectpicker('refresh');
    }
}

