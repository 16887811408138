import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import '../scss/components/news.scss';

const $ = require('jquery');
global.$ = global.jQuery = $;
import 'jquery.cookie';
import 'jquery.scrollto';
import 'bootstrap';
import 'bootstrap-select/dist/js/bootstrap-select';
import 'bootstrap-select/dist/js/i18n/defaults-ru_RU';
import './context/ckeditor4/basePath';
import '../../cms/ckeditor_4.4.6/ckeditor';
import 'sprintf-js';
import './old_vendor/js/pushstream';
import '../../design/js/main';
import '../../design/js/main_sklad';
import '../../design/js/ws_all';
import ModalBannerNews from './news/modal.banner.news';
import ModalMessageNews from './news/modal.message.news';
import News from './news/news';

$(function () {
    let modalBannerNews = new ModalBannerNews();
    let modalMessageNews = new ModalMessageNews();
    let news = new News();
    news.events();
    modalBannerNews.callbackAfterClickSave = modalMessageNews.callbackAfterClickSave = news.callbackAfterRemove = function () {
        location.reload();
    };
    let $news = $('.news');
    $news
        .on('click.add', '.btn-add-banner', modalBannerNews.handlerAdd.bind(modalBannerNews))
        .on('click.edit', '.btn-edit-banner', modalBannerNews.handlerEdit.bind(modalBannerNews))
        .on('click.add', '.btn-add-message', modalMessageNews.handlerAdd.bind(modalMessageNews))
        .on('click.edit', '.btn-edit-message', modalMessageNews.handlerEdit.bind(modalMessageNews))
        .on('click.remove', '.btn-remove', news.handlerConfirmRemove.bind(news))
    ;
});
