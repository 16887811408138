import $ from 'jquery';
import News from './news';
import FilterModalNews from './filter.modal.news';

export default class ModalMessageNews {
    constructor() {
        this.news = new News();
    }

    _prepare() {
        this.$title = this.$modal.find('#title');
        this.$text = this.$modal.find('#text');
        CKEDITOR.env.isCompatible = true;
        this.editor = CKEDITOR.replace(this.$text.get(0), {
            width: '100%',
            resize_enabled: false,
            toolbarGroups: [
                {name: 'document', groups: ['mode', 'document', 'doctools']},
                {name: 'clipboard', groups: ['clipboard', 'undo']},
                {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
                {name: 'forms', groups: ['forms']},
                {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
                {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
                {name: 'links', groups: ['links']},
                {name: 'insert', groups: ['insert']},
                {name: 'styles', groups: ['styles']},
                {name: 'colors', groups: ['colors']},
                {name: 'tools', groups: ['tools']},
                {name: 'others', groups: ['others']},
                {name: 'about', groups: ['about']}
            ],
            removeButtons: 'Save,Templates,NewPage,ExportPdf,Preview,Print,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,Flash,Table,HorizontalRule,Smiley,PageBreak,Iframe,Styles,Format,Font,About,ShowBlocks,TextColor,BGColor,Language',
            filebrowserImageBrowseUrl: '/cms/ckeditor/plugins/ckfinders3/ckfinder.html?type=Images',
            filebrowserImageUploadUrl: '/cms/ckeditor/plugins/ckfinders3/core/connector/s3/connector.php?command=QuickUpload&type=Images'
        });
        this.filter = new FilterModalNews(this.$modal);
        this.$btnSave = this.$modal.find('.btn-save');
        this.$modal
            .on('show.bs.modal.prepare', () => {
                let id = this.$modal.data('id');
                let title = id ? 'Редактирование новости' : 'Добавление новости';
                this.$modal.find('.modal-title').text(title);
            })
            .on('shown.bs.modal.focus', () => {
                this.$title.focus();
            })
            .on('hidden.bs.modal.clear', () => {
                this.$title.val('')
                    .closest('.form-group').removeClass('has-error')
                ;
                this.editor.setData('');
                this.$text.closest('.form-group').removeClass('has-error');
                this.filter.clear();
                this.$btnSave.prop('disabled', false);
                this.$modal.removeAttr('data-id').removeData('id');
            })
            .on('click', '.btn-save', () => {
                this.$title.closest('.form-group').removeClass('has-error');
                if (!this.$title.val().trim().length) {
                    this.$title.closest('.form-group').addClass('has-error');
                }
                this.$text.closest('.form-group').removeClass('has-error');
                let text = this.editor.getData();
                let $divText = $('<div/>', {html: text});
                if (
                    !$divText.text().trim().length
                    && !$divText.find('img').length
                ) {
                    this.$text.closest('.form-group').addClass('has-error');
                }
                let $form = this.$modal.find('form');
                let $hasError = $form.find('.has-error');
                if (!$hasError.length) {
                    let formData = new FormData($form.get(0));
                    formData.append('type', '2');
                    let id = this.$modal.data().id;
                    if (id) {
                        formData.append('id', id.toString());
                    }
                    formData.set('text', text);
                    this.filter.getLocation().forEach((item, index) => {
                        for (let key in item) {
                            if (!item.hasOwnProperty(key)) {
                                continue;
                            }
                            let value = item[key];
                            formData.append(`location[${index}][${key}]`, value.toString());
                        }
                    });
                    this.$btnSave.prop('disabled', true);
                    this.news.set(formData).then(data => {
                        this.$modal.modal('hide');
                        this.callbackAfterClickSave(data);
                    }).catch(error => {
                        console.error(error);
                    });
                } else {
                    this.$modal.scrollTop($hasError.offset().top + this.$modal.scrollTop())
                }
            })
        ;
    }

    init(callback) {
        let $modal = $('#modalNewsMessage');
        if ($modal.length) {
            this.$modal = $modal;
            this._prepare();
            callback && callback();
            return true;
        }
    }

    callbackAfterClickSave(data) {
    }

    handlerAdd(event) {
        if (!this.$modal) {
            return this.init(this.handlerAdd.bind(this, event));
        }
        event.preventDefault();
        this.$modal.modal('show');
    }

    handlerEdit(event) {
        if (!this.$modal) {
            return this.init(this.handlerEdit.bind(this, event));
        }
        event.preventDefault();
        let $self = $(event.currentTarget);
        let id = $self.data().id;
        this.news.get(id).then(data => {
            let title = $('<textarea/>').html(data.title).text();
            this.$title.val(title);
            this.editor.setData(data.text);
            let filter = data.filter;
            if (null !== filter.access_to_orders) {
                this.filter.$accessToOrders.selectpicker('val', +filter.access_to_orders);
            }
            if (null !== filter.show_packed_remains) {
                this.filter.$showPackedRemains.selectpicker('val', +filter.show_packed_remains);
            }
            if (null !== filter.client_type) {
                this.filter.setClientType(filter.client_type);
            }
            this.filter.setLocation(filter.location);
            this.$modal.attr('data-id', id).data('id', id);
            this.$modal.modal('show');
        }).catch(error => {
            console.error(error);
        });
    }
}
