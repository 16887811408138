$(document).ready(function () {

    $('#ddl-sel-city .head-city').text($('#head-ddl li.select span').text());

    //setPageSize(false);

    // Выбор города в шапке сайта -----------------------------------------------------
    $('#open-sel').click(function () {
        if ($('#head-ddl').is(':visible')) {
            $('#head-ddl').hide();
        } else {
            $('#head-ddl').show();
        }
    });
    $('#head-ddl li').click(function () {
        $.cookie('at_user_city_id', $(this).attr('id'));
        $('#ddl-sel-city .head-city').text($(this).text());
        $('#head-ddl').hide();
        location.reload();
    });
    $('body').on('click', function (e) {
        if ($(e.target).closest('#ddl-sel-city').length == 0 && $('#head-ddl').is(':visible'))
            $('#head-ddl').hide();
    });

    // Страница "Контакты" -------------------------------------------------------------
    $(".phone").click(function () {
        var $this = $(this);
        $.get("/index/index.php?m=_getPhone", {"id": $(this).attr("id")}, function (data) {
            /* Обр.196602
             * Добавлена возможность вывода нескольких номеров телефонов
             */
            try {
                var phones = String(data).split(',');
                if (phones.length > 1) {
                    $this.css('height', (17 * phones.length) + 'px');
                    data = phones.join('<br />');
                    $this.prev('br').remove(); /*Обр.208712*/
                }
            } catch (e) {
            }
            $this.html(data);
        }, "html");
    });
    $(".email").click(function () {
        var $this = $(this);
        $.get("/index/index.php?m=_getEmail", {"id": $(this).attr("id")}, function (data) {
            /* Обр.196602
             * Добавлена возможность вывода нескольких адресов электронной почты
             */
            try {
                var emails = String(data).split(',');
                if (emails.length > 1) {
                    $this.css('height', (17 * emails.length) + 'px');
                    data = emails.join('<br />');
                    $this.prev('br').remove(); /*Обр.208712*/
                }
            } catch (e) {
            }
            $this.html('<a href="mailto:' + data + '" class="mail-link">' + data + '</a>');
        }, "html");
    });

    // Страница "Вакансии" -------------------------------------------------------------
    $('ul#menu-city').delegate('a', 'click', function () {
        $('ul#menu-city span').each(function () {
            $(this).replaceWith('<a href="javascript:void(0);" id="' + $(this).attr('id') + '" title="' + i18next.t('Просмотреть список вакансий') + '">' + $(this).text() + '</a>');
        });
        $(this).replaceWith('<span id="' + $(this).attr('id') + '">' + $(this).text() + '</span>');
        $('.name-city, .vacancys, .vacancy').hide();
        if ($('.vacancys .vacancy.' + $(this).attr('id')).length > 0) {
            $('.name-city').text($(this).text()).show();
            $('.vacancys').show().find('.vacancy.' + $(this).attr('id')).show();
        }
        setPageSize(true);
    });

    // Н.114224 a.gladkikh
    //$("body").trigger("resize");

    // P: 4614 H: 115815
    if ($('.topMenu0').length) { // O: 209221
        minWidthTopMenuAndBody();
    }
    if (location.protocol === 'https:') {
        $('.positivessl')
            .removeClass('hide');
    }
    // consultant
    if (typeof $ != "undefined") {
        if ($('input[type=hidden][name=s_user_id]').length) {
            if (!+$('input[type=hidden][name=s_1c_id_def]').val()) {
                var _data = {
                    groupid: $('input[type=hidden][name=c_at_user_city_id]').val() ? +$('input[type=hidden][name=c_at_user_city_id]').val() : ''
                    , uid: +$('input[type=hidden][name=s_user_id]').val()
                    , memail: $('.managerBlock .managerInfo a[href^=mailto]').text().trim()
                }
            } else {
                var _data = {
                    groupid: $('input[type=hidden][name=c_at_user_city_id]').val() ? +$('input[type=hidden][name=c_at_user_city_id]').val() : ''
                }
            }
        } else {
            var _data = {
                // site_for_validating : window.location.hostname
                site_for_validating: location.host.replace('www.', '')
            }
        }
    }
    switch (location.pathname) {
        case '/':
            break;
        case '/brands/':
            /* P: 5058 H: 123169 */
            if ($('input[name=s_user_id]').val()) {
                var $removethetwotr = $('#top').find('table.topTable>tbody');
                $removethetwotr
                    .find('tr:eq(0)')
                    .empty();
            }
            break;
        case '/management/site/':
            $('input[name="sendOrderMode[]"]').change(function () {
                $('input[name="sendOrderMode[]"]').not($(this)).prop("checked", false);
            });
            var $deliveryRoutes = $('#collapseDeliveryRoutes');
            var getStockDeliveryRoutes = function (params) {
                if (typeof params !== 'object') {
                    params = {};
                }
                var $tbody = $deliveryRoutes.find('tbody');
                var item = {
                    is_used: false,
                    inside_id_stock: '',
                    filial_name: '',
                    name_stock: '',
                    delivery_routes: '',
                    is_deleted: false
                };
                var tds = [];
                for (var column in item) {
                    var value = $('<span/>', {
                        id: 'search-loader',
                        class: 'g-png24 preloader',
                        css: {
                            left: 0,
                            margin: 0
                        }
                    });
                    switch (column) {
                        case 'is_used':
                            value = $('<input/>', {
                                type: 'checkbox',
                                checked: params.is_used && params.is_used == 1 ? true : false,
                                'data-journal-current': params.is_used && params.is_used == 1 ? 1 : 0,
                            });
                            break;
                        case 'inside_id_stock':
                            break;
                        case 'filial_name':
                            break;
                        case 'name_stock':
                            break;
                        case 'delivery_routes':
                            value = $('<textarea/>', {
                                class: 'form-control',
                                row: 2,
                                value: params.delivery_routes && params.delivery_routes.length ? params.delivery_routes : '',
                                'data-journal-current': params.delivery_routes && params.delivery_routes.length ? params.delivery_routes : '',
                            });
                            break;
                        case 'is_deleted':
                            value = $('<span/>', {
                                class: 'glyphicon glyphicon-remove',
                                'aria-hidden': true
                            });
                            break;
                    }
                    tds.push($('<td/>', {
                        class: column.replace(/_/g, '-'),
                        append: value
                    }));
                }
                $tbody.append($('<tr/>', {
                    'data-id': params.id,
                    append: tds,
                    class: 'journal-param-block'
                }));
                getStockDeliveryAddresses({
                    id: params.id,
                    id_group_stocks: params.id_group_stocks
                });
            };
            $.post('/user/?m=profile&n=getStockDeliveryRoutes', function (data) {
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    data = {};
                }
                if (data) {
                    for (var column in data) {
                        var item = data[column];
                        getStockDeliveryRoutes(item);
                    }
                }
                if ($deliveryRoutes.find('.table tbody').children().length) {
                    $deliveryRoutes.find('form').show();
                }
            });
            $deliveryRoutes.on('click.add', '.btn-add', function () {
                $.post('/user/?m=profile&n=addStockDeliveryRoutes', function (data) {
                    try {
                        data = JSON.parse(data);
                    } catch (e) {
                        data = {};
                    }
                    if (data.code == 0) {
                        getStockDeliveryRoutes({
                            id: data.id
                        });
                        $deliveryRoutes.find('form').show();
                    } else {
                        getModal({
                            body: $('<p/>', {
                                text: data.msg
                            }),
                            footer: $('<button/>', {
                                type: 'button',
                                class: 'btn btn-default',
                                'data-dismiss': 'modal',
                                html: $('<span/>', {
                                    class: 'glyphicon glyphicon-remove',
                                    'aria-hidden': true
                                }),
                                append: ' ' + i18next.t('Закрыть')
                            })
                        }).modal('show');
                    }
                });
            });
            $deliveryRoutes.on('click.save', '.btn-save', function () {
                var $this = $(this);
                $this.prop('disabled', true);
                var $table = $deliveryRoutes.find('.table');
                var column = 0;
                $table.find('tbody tr').each(function () {
                    var $tr = $(this);
                    var stock = $tr.find('.name-stock .selectpicker :selected').data();
                    var params = {
                        id: $tr.attr('data-id'),
                        is_used: $tr.find('.is-used input[type="checkbox"]').is(':checked') ? 1 : 0,
                        id_group_stocks: stock ? stock.id : 0,
                        delivery_routes: $tr.find('.delivery-routes textarea').val(),
                        // [изм.9323 н.0000165157]
                        'data-journal-section': $deliveryRoutes.find('[name = "data-journal-section"]').val(),
                        'data-journal-block': $deliveryRoutes.find('[name = "data-journal-block"]').val()
                    };
                    var url = '/user/?m=profile&n=setStockDeliveryRoutes';
                    url += '&' + Date.now();
                    $.post(url, params, function (data) {
                        try {
                            data = JSON.parse(data);
                        } catch (e) {
                            data = {};
                        }
                        if (data.code == 0) {
                            if (++column == $table.find('tbody tr').length) {
                                $this.prop('disabled', false);
                                $deliveryRoutes.find('.msg-succes').fadeIn('fast', function () {
                                    var $this = $(this);
                                    $this.fadeOut(10000);
                                });
                            }
                        } else {
                            getModal({
                                body: $('<p/>', {
                                    text: data.msg
                                }),
                                footer: $('<button/>', {
                                    type: 'button',
                                    class: 'btn btn-default',
                                    'data-dismiss': 'modal',
                                    html: $('<span/>', {
                                        class: 'glyphicon glyphicon-remove',
                                        'aria-hidden': true
                                    }),
                                    append: ' ' + i18next.t('Закрыть')
                                })
                            }).modal('show');
                        }
                    });
                });
            });
            $deliveryRoutes.on('changed.bs.select.name.stock', '.name-stock .selectpicker', function () {
                var $this = $(this);
                var stock = $this.find(':selected').data();
                var filial = $this.find(':selected').closest('optgroup').data();
                var $tr = $this.closest('tr');
                $tr.find('.inside-id-stock').text(stock && typeof stock === 'object' ? stock.inside_id : '-');
                $tr.find('.filial-name').text(filial && typeof filial === 'object' ? filial.name : '-');
            });
            $deliveryRoutes.on('click.del', '.is-deleted .glyphicon-remove', function () {
                var $this = $(this);
                var $tr = $this.closest('tr');
                getModal({
                    body: $('<p/>', {
                        text: i18next.t('Вы уверены, что хотите удалить данные настройки склада?')
                    }),
                    footer: $().add(
                        $('<button/>', {
                            type: 'button',
                            class: 'btn btn-primary btn-ok',
                            'data-dismiss': 'modal',
                            text: ' ' + i18next.t('Да'),
                            prepend: $('<span/>', {
                                class: 'glyphicon glyphicon-ok',
                                'aria-hidden': true
                            })
                        })
                    ).add(
                        $('<button/>', {
                            type: 'button',
                            class: 'btn btn-default',
                            'data-dismiss': 'modal',
                            text: ' ' + i18next.t('Отмена'),
                            prepend: $('<span/>', {
                                class: 'glyphicon glyphicon-remove',
                                'aria-hidden': true
                            })
                        })
                    )
                }).modal('show').on('click.rmv', '.btn-ok', function () {
                    var stock = $tr.find('.name-stock .selectpicker :selected').data();
                    var params = {
                        id: $tr.attr('data-id'),
                        id_group_stocks: stock ? stock.id : 0,
                    };
                    $.post('/user/?m=profile&n=delStockDeliveryRoutes', params, function (data) {
                        try {
                            data = JSON.parse(data);
                        } catch (e) {
                            data = {};
                        }
                        if (data.code == 0) {
                            $tr.remove();
                            if (!$deliveryRoutes.find('.table tbody').children().length) {
                                $deliveryRoutes.find('form').hide();
                            }
                        } else {
                            getModal({
                                body: $('<p/>', {
                                    text: data.msg
                                }),
                                footer: $('<button/>', {
                                    type: 'button',
                                    class: 'btn btn-default',
                                    'data-dismiss': 'modal',
                                    html: $('<span/>', {
                                        class: 'glyphicon glyphicon-remove',
                                        'aria-hidden': true
                                    }),
                                    append: ' ' + i18next.t('Закрыть')
                                })
                            }).modal('show');
                        }
                    });
                });
            });
            $deliveryRoutes.on('input.validate', '.delivery-routes textarea', function () {
                this.value = this.value.replace(/[^а-яА-Я0-9,]/g, '');
            });
            break;
        case '/user/':
        case '/management/shop/':
            $(document).ready(function () {
                var button = "";
                var $notselectplag = 1;
                var submit_val = 0;
                // н 65271: если нельзя изменять код 1С, то запрещаем изменять страну
                if ($("#id_1c_id").is(":disabled")) {
                    $("#stateSelect").attr("disabled", true);
                    $("#stateSelect").after('<input type="hidden" name="f[state]" value="' + $("#stateSelect").val() + '">'); // н 94808
                }
                if ($("#stateSelect").val() == 0) $("#regionSelect").hide();
                if ($("#regionSelect").val() == 0) $("#townSelect").hide();
                // н 65271: если выбран "Другой город", то показываем поле для указания города
                if ($("#townSelect").val() == -1) $("#id_city").closest("tr").show();
                var fl = false;
                // $("#stateSelect").change(function(){
                $(document).on('change', '#stateSelect', function () {
                    fl = true;
                    if ($("#userStateId").length > 0) { /*110744 4346*/
                        if ($("#id_tel_fed:eq(0)").val() != "") {
                            var fl = confirm(i18next.t("После смены страны номер телефона в профиле пользователя будет удален. Вы действительно хотите изменить страну?"));
                            if (!fl) {
                                $("#stateSelect").val($("#userStateId").val());
                                return false;
                            } else {
                                if ($("#tel_fed_def").length === 0) {
                                    $("#id_tel_fed:eq(0)").after('<input type="hidden" id="tel_fed_def" value="' + $("#id_tel_fed:eq(0)").val() + '">');
                                }
                                $("input[name=\"f[tel_fed]\"]").val("");
                            }
                        }
                        if ($("#stateSelect").val() == $("#userStateId").val()) {
                            $("input[name=\"f[tel_fed]\"]").val($("#tel_fed_def").val());
                        }
                    }
                    $("#regionSelComm").empty().show();
                    $("#regionSelect").hide();
                    $("#townSelect option:selected").each(function () {
                        this.selected = false;
                    });
                    $("#townSelect").hide();
                    // н 65271: если изменилась Страна, то скрываем поле "Другой город"
                    if ($("#id_city").closest("tr").is(":visible")) {
                        $("#id_city").closest("tr").hide();
                    }
                    if ($("#stateSelect").val() !== "") {
                        var results = new RegExp("[\\?&]m=([^&#]*)").exec(window.location.href);
                        if (results[1] != "reg") {
                            $.ajaxSetup({async: false});
                            $.post("/user/?m=_changeStorages", {
                                sid: $("#stateSelect").val(),
                                s1c_id: $("#id_1c_id").val(),
                                user_id: $("#user_id").val()
                            }, function (data) {
                                $("#content_storages").replaceWith(data);
                            });
                            // н 65271: показываем блок со складами и очищаем Код 1С
                            $.ajaxSetup({async: true});
                            if ($("#id_1c_id").is(":enabled")) {
                                $("#id_1c_id").val("");
                                $("#hidden_id").val("");
                                $("#id_1c_id").css("background-color", "#FAFFBD");
                                $("#content_storages").show();
                            }
                        }
                        $("#regionSelComm").html("<b>" + i18next.t('Обновление списка регионов...') + "</b>");
                        $.post("/user/?m=_getRegion", {
                            sid: $("#stateSelect").val(),
                            s1c_id: $("#id_1c_id").val()
                        }, function (data) {
                            // [р.5239 н.0000138746]
                            if (typeof data === 'object') {
                                if (data.code === 0) {
                                    $("#regionSelect").show().html(data.regionlist);
                                    $("#regionSelComm").hide();
                                    var results = new RegExp("[\\?&]m=([^&#]*)").exec(window.location.href);
                                    if (results[1] != "reg")
                                        $("table.fForms").find("tr:eq(9)").find("td.fieldName2").html(data.storageList);
                                } else {
                                    alert(i18next.t("Не удалось обновить список регионов. Повторите попытку позже."));
                                }
                            } else {
                                alert(i18next.t("Не удалось обновить список регионов. Повторите попытку позже."));
                            }
                            /*data = eval("("+data+")");
                            if(data.message == "get_ok") {
                                $("#regionSelect").show().html(data.regionlist);
                                $("#regionSelComm").hide();
                                var results = new RegExp("[\\?&]m=([^&#]*)").exec(window.location.href);
                                if(results[1] != "reg")
                                    $("table.fForms").find("tr:eq(9)").find("td.fieldName2").html(data.storageList);
                            }*/
                        });
                    }
                });
                // $("#regionSelect").change(function(){
                $(document).on('change', '#regionSelect', function () {
                    $("#townSelComm").empty().show();
                    $("#townSelect").hide();
                    // н 65271: если изменился Регион, то скрываем поле "Другой город"
                    if ($("#id_city").closest("tr").is(":visible")) {
                        $("#id_city").closest("tr").hide();
                    }
                    if ($("#regionSelect").val() !== "") {
                        $("#townSelComm").html("<b>" + i18next.t('Обновление списка городов...') + "</b>");
                        $.post("/user/?m=_getTowns", {rid: $("#regionSelect").val()}, function (data) {
                            if (typeof data === 'object') {
                                if (data.code === 0) {
                                    $('#townSelect')
                                        .show()
                                        .html(data.townlist);
                                    if (typeof $('#townSelect').selectpicker == 'function') {
                                        $('#townSelect').selectpicker('refresh');
                                    }
                                    $(".btn-group.bootstrap-select.form-control").css({
                                        width: "405px",
                                        "margin-bottom": "10px"
                                    });
                                    $("#townSelComm").hide();
                                } else {
                                    alert(i18next.t("Не удалось обновить список городов. Повторите попытку позже или укажите город в текстовом поле."));
                                }
                            } else {
                                alert(i18next.t("Не удалось обновить список городов. Повторите попытку позже или укажите город в текстовом поле."));
                            }
                            /*data = eval("(" + data + ")");
                            if (data.message == "get_ok") {
                                //$("#townSelComm").hide();
                                $('#townSelect')
                                    .show()
                                    .html(data.townlist);
                                if ( typeof $('#townSelect').selectpicker == 'function' ) {
                                    $('#townSelect').selectpicker('refresh');
                                }

                                $(".btn-group.bootstrap-select.form-control").css({
                                    width: "405px",
                                    "margin-bottom": "10px"
                                });
                                $("#townSelComm").hide();
                            } else {
                                alert("Не удалось обновить список городов. Повторите попытку позже или укажите город в текстовом поле.");
                            }*/
                        });
                    }
                    let $freeZone = $('[name="f[free_zone_id]"]');
                    $freeZone.closest('tr').addClass('hidden');
                    $freeZone.empty().selectpicker('refresh');
                });
                // н 65271: если из списка городов выбран "Другой город", то показываем поле ввода "Другой город"
                // $("#townSelect").change(function(){
                $(document).on('change', '#townSelect', function () {
                    if ($(this).val() == -1) {
                        $("#id_city").closest("tr").show();
                    } else if ($("#id_city").closest("tr").is(":visible")) {
                        $("#id_city").closest("tr").hide();
                    }
                    if (fl) alert(i18next.t("Укажите новый код 1С и склады, по которым пользователь может просматривать наличие и цены."));
                    let $this = $(this);
                    let $freeZone = $('[name="f[free_zone_id]"]');
                    $freeZone.closest('tr').addClass('hidden');
                    $freeZone.empty().selectpicker('refresh');
                    $.post('/user/?m=_getFreeZonesByCityId', {city_id: +$this.val()}, data => {
                        if (data && data.length) {
                            $freeZone.append(`<option>${i18next.t('Ничего не выбрано')}</option>`);
                            $freeZone.append('<option data-divider="true"></option>');
                            data.forEach(datum => {
                                $freeZone.append(`<option value="${datum.id}">${datum.name}</option>`);
                            });
                            $freeZone.selectpicker('refresh');
                            $freeZone.closest('tr').removeClass('hidden');
                        }
                    }).fail(jqXHR => {
                        console.error(jqXHR);
                    });
                });

            });

        function nooo() {
            submit_val = 0;
            $("#reg_form :input:not(input[type=submit],input[type=button])").removeAttr("disabled");
            $("#reg_form > center").html(button);
        }

            // validate
            arr_length_fields = {
                id_ur_orgname: 256	// Наименование организации
                , id_ur_genfio: 256	// ФИО ген. директора
                , id_ur_adress: 256	// Юридический адрес
                , id_ur_pstadress: 256	// Фактический адрес
                , id_ur_inn: 20	// ИНН
                , id_ur_ogrn: 50	// ОГРН
                , id_ur_rch: 256 // Р/с (с указанием банка)
                , id_ur_kpp: 20	// КПП
                , id_ur_ksch: 256	// Кор/счет
                , id_ur_bik: 20	// БИК
                , id_ur_phone: 256	// Телефон
                , id_ur_fax: 256	// Факс
                , id_ip_data: 256	// Реквизиты для индивидуального предпринимателя
                // etc
                , id_1c_id: 8	// Код 1С
            }
            $.each(
                $('.userField')
                , function (key, value) {
                    if (arr_length_fields[$(value).attr('id')] != null) {
                        $(value)
                            .attr(
                                'maxlength'
                                , arr_length_fields[$(value).attr('id')]
                            );
                    }
                }
            );
            break;
        case '/management/notify/':
            CKEDITOR.replace('message', {
                filebrowserImageBrowseUrl: '/cms/ckeditor/plugins/ckfinder/ckfinder.html?type=Images',
                filebrowserImageUploadUrl: '/cms/ckeditor/plugins/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images',
                removePlugins: 'resize',
                width: '100%',
                toolbar: [
                    {
                        name: 'clipboard',
                        groups: ['clipboard', 'undo'],
                        items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
                    },
                    {name: 'links', items: ['Link', 'Unlink', 'Anchor']},
                    {
                        name: 'basicstyles',
                        groups: ['basicstyles', 'cleanup'],
                        items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
                    },
                    '/',
                    {name: 'styles', items: ['Font', 'FontSize']}
                ]
            });
            break;
        case '/management/feedback/':
            var showLnkRep = Array();
            $(".sendButton").click(
                function () {
                    var mid = parseInt($(this).attr("mid"));
                    var to = $("#to_" + mid).val();
                    var subj = $("#subj_" + mid).val();
                    var text = $("#message_" + mid).val();
                    var site = $("#site_" + mid).val();
                    if (to.length > 0 && subj.length > 0 && text.length > 0) {
                        $("#cmdSend_" + mid).attr("value", i18next.t("Отправка письма..."));
                        $("#cmdSend_" + mid).attr("disabled", true);
                        $.post(
                            "/management/feedback/?m=sendReplay&aj=true",
                            {"to": to, "subj": subj, "mess": text, "mid": mid, "site": site},
                            function (data) {
                                data = eval("(" + data + ")");
                                $("#cmdSend_" + mid).attr("value", i18next.t("Отправить"));
                                $("#cmdSend_" + mid).attr("disabled", false);
                                if (data.message == "send_ok") {
                                    alert(i18next.t("Сообщение успешно отправлено!"));
                                    $("#lnkReplay_" + mid).click();
                                    $("#tr_" + mid).attr("bgcolor", "#ffffff");
                                    $("#tr_" + mid).find("td:first span.mess-not-replied")
                                        .removeClass("mess-not-replied")
                                        .addClass("mess-replied")
                                        .html(i18next.t("обработано"));
                                    $("#replymsg_" + mid).html($("#replymsg_" + mid).html() + '<p><a href="/management/shop/?m=UserInfo&uid=' + data.user_id + '">' + data.user_name + '</a></p>' + text.replace(/<(.|\n)*?>/g, ''));
                                } else {
                                    alert(i18next.t("Ошибка") + ". " + data.message);
                                }
                            }
                        );
                    } else {
                        alert(i18next.t("Внимание! Все поля обязательны для заполнения!"));
                    }
                    return false;
                }
            );
            $(".depChBtn").click(function () {
                var mid = parseInt($(this).attr("mid"));
                var new_dep = $("#xdep_" + mid).val();
                $.post(
                    "/management/feedback/?m=changeDepartment&aj=true",
                    {"to_dep": new_dep, "mid": mid},
                    function (data) {
                        data = eval("(" + data + ")");
                        if (data.message == "ok") {
                            alert(i18next.t("Сообщение успешно перенаправлено в другую группу онлайн-консультанта."));
                        } else {
                            alert(i18next.t("Ошибка") + ". " + data.message);
                        }
                    }
                );
                return false;
            });
            $(".lnkReplay").click(
                function () {
                    var mid = parseInt($(this).attr("mid"));
                    if (showLnkRep[mid] != undefined && showLnkRep[mid] == true) {
                        $("#areaReplay_" + mid).hide();
                        $(this).text(i18next.t("Ответить") + " »»");
                        showLnkRep[mid] = false;
                    } else {
                        $("#areaReplay_" + mid).show();
                        $(this).text(i18next.t("Ответить") + " ««");
                        showLnkRep[mid] = true;
                    }
                    return false;
                }
            );
            break;
        case '/login/':
            $(document).ready(function () {
                $('.container').css('margin-top', '0');
                var middleScreen = $(window).height() / 2;
                var heightContainer = $('.container').height() / 2;
                var topContainer = $('.container').offset().top
                var marginTop = middleScreen - heightContainer - topContainer;
                if (marginTop > 0) {
                    $('.container').css('margin-top', marginTop);
                } else {
                    $('.container').css('margin-top', '0');
                }
                $(window).resize(function () {
                    $('.container').css('margin-top', '0');
                    var middleScreen = $(window).height() / 2;
                    var heightContainer = $('.container').height() / 2;
                    var topContainer = $('.container').offset().top
                    var marginTop = middleScreen - heightContainer - topContainer;
                    if (marginTop > 0) {
                        $('.container').css('margin-top', marginTop);
                    } else {
                        $('.container').css('margin-top', '0');
                    }
                })
            });

        function getNewPosition() {
            $('.container').css('margin-top', '0');
            var middleScreen = $(window).height() / 2;
            var heightContainer = $('.container').height() / 2;
            var topContainer = $('.container').offset().top
            var marginTop = middleScreen - heightContainer - topContainer;
            if (marginTop > 0) {
                $('.container').css('margin-top', marginTop);
            } else {
                $('.container').css('margin-top', '0');
            }
        }

            break;
        default:
        // etc
    }

    if ('en' !== i18next.language) {
        globalCheckFIO();
    }
});

$(window).resize(function () {
    setPageSize(true);
});

// Прицепляем футер к низу страницы
function setPageSize(isResize) {
    if ($('body').height() < $(window).height())
        $('.bodycontent').css('height', $(window).height());
    else if (isResize)
        $('.bodycontent').removeAttr('style').css('height', document.documentElement.scrollHeight);
}

// P: 4614 H: 115815
function minWidthTopMenuAndBody() {
    return false; /*209338 и.12183*/
    // O: 215915
    if (
        ~$.inArray(
            location.pathname + location.search
            , [
                '/'
                , '/user/?m=reg'
                , '/login/?m=forgot'
            ]
        )
    ) {
        return false;
    }
    // if (
    //     $('.tmLeft').outerWidth() + $('.tmRight').outerWidth() > $('.topMenu0').outerWidth()
    //     || parseInt($('.topMenu0').css('min-height'), 10) < $('.topMenu0').outerHeight()
    // ) {
    //     $('html, .topMenu0')
    //         .css(
    //             'min-width'
    //             // 'width'
    //             , $('.tmLeft').outerWidth() + $('.tmRight').outerWidth()
    //         );
    // }
    var body = document.body;
    var topMenu0 = $('.topMenu0');
    var topMenu = $('.topMenu');
    var tmLeft = $('.tmLeft');
    var tmRight = $('.tmRight');
    // P: 4614 H: 115827
    topMenu0 = topMenu0[0] || topMenu0;
    topMenu = topMenu[0] || topMenu;
    tmLeft = tmLeft[0] || tmLeft;
    tmRight = tmRight[0] || tmRight;
    var topTable = document.querySelector('table.topTable');
    if (tmLeft.offsetWidth + tmRight.offsetWidth > topMenu.offsetWidth) {
        body.style.minWidth =
            topMenu.style.minWidth =
                topMenu0.style.minWidth = tmLeft.offsetWidth + tmRight.offsetWidth + 10 + "px";
    }
    if (topTable.offsetWidth > body.offsetWidth) {
        body.style.minWidth =
            topMenu.style.minWidth =
                topMenu0.style.minWidth = topTable.offsetWidth + "px";
    }
    // альтернатива для двух вариантов
    if ((tmLeft.offsetWidth + tmRight.offsetWidth > topMenu.offsetWidth) && (topTable.offsetWidth > body.offsetWidth)) {
        body.style.minWidth =
            topMenu.style.minWidth =
                topMenu0.style.minWidth = Math.max(tmLeft.offsetWidth + tmRight.offsetWidth, topTable.offsetWidth) + "px";
    }
}

function getStockDeliveryAddresses(params) {
    if (typeof params !== 'object') {
        params = {};
    }
    var url = '/user/?m=profile&n=getStockDeliveryAddresses';
    if (params.id) {
        url += '&id=' + params.id;
    }
    $.post(url, params, function (data) {
        try {
            data = JSON.parse(data);
        } catch (e) {
            data = {};
        }
        var $select = $('<select/>', {
            class: 'selectpicker',
            'data-live-search': true
        });
        var items = {};
        for (var column in data) {
            var item = data[column];
            var filial_name = item.filial.name;
            if (!items[filial_name]) {
                items[filial_name] = {};
            }
            if (!items[filial_name].stock) {
                items[filial_name].stock = [];
            }
            items[filial_name].stock.push({
                id: item.id,
                inside_id: item.inside_id,
                name: item.name,
                for_delivery: item.for_delivery
            });
            if (!items[filial_name].filial) {
                items[filial_name].filial = item.filial;
            }
        }
        var list = [];
        var is_clear = true;
        for (var filial_name in items) {
            var item = items[filial_name];
            var $optgroup = $('<optgroup/>', {
                label: item.filial.name,
                data: item.filial
            });
            for (var column in item.stock) {
                var stock = item.stock[column];
                var is_selected = false;
                if (params.id_group_stocks && params.id_group_stocks == stock.id) {
                    is_selected = true;
                    is_clear = false;
                }
                $optgroup.append($('<option/>', {
                    text: stock.name,
                    data: stock,
                    selected: is_selected
                }));
            }
            list.push($optgroup);
        }
        $select.append(list);
        var $deliveryRoutes = $('#collapseDeliveryRoutes');
        var $table = $deliveryRoutes.find('.table');
        var $name_stock;
        if (params.id) {
            $name_stock = $table.find('tr[data-id=":id"]'.replace(':id', params.id)).find('.name-stock');
        } else {
            $name_stock = $table.find('tr .name-stock:last');
        }
        $name_stock.empty().append($select);
        $selectpicker = $name_stock.find('.selectpicker');
        if (is_clear) {
            $selectpicker.selectpicker('val', '');
        } else {
            $selectpicker.selectpicker();
        }
        $selectpicker.trigger('changed.bs.select.name.stock');
    });
}

function getModal(params) {
    if (typeof params !== 'object') {
        params = {};
    }
    if (params.id === undefined) {
        params.id = 'modal' + Date.now();
    }
    if (params.body === undefined) {
        params.body = '';
    }
    if (params.footer === undefined) {
        params.footer = '';
    }
    var $header = $('<div/>', {
        class: 'modal-header',
        append: $().add(
            $('<button/>', {
                type: 'button',
                class: 'close',
                'data-dismiss': 'modal',
                'aria-label': 'Close',
                append: $('<span/>', {
                    'aria-hidden': 'true',
                    html: '&times;'
                })
            })
        ).add(
            $('<h4/>', {
                class: 'modal-title'
            })
        )
    });
    var $body = $('<div/>', {
        class: 'modal-body'
    });
    var $footer = $('<div/>', {
        class: 'modal-footer'
    });
    var $dialog = $('<div/>', {
        class: 'modal-dialog',
        role: 'document',
        append: $('<div/>', {
            class: 'modal-content',
            append: $().add($header).add($body).add($footer)
        })
    });
    var $modal = $('<div/>', {
        class: 'modal fade',
        tabindex: -1,
        role: 'dialog',
        append: $dialog
    });
    $modal.attr({
        id: params.id
    });
    if (params.title !== undefined) {
        $modal.find('.modal-title').text(params.title);
    } else {
        $modal.find('.modal-header').addClass('hidden');
    }
    $modal.find('.modal-body').append(params.body).end()
        .find('.modal-footer').append(params.footer);
    $modal.on('shown.bs.modal', function () {
        $(this).find('.modal-dialog').css({
            'margin-top': $(window).height() / 2 - $(this).find('.modal-content').height() / 2
        });
    }).on('hidden.bs.modal', function () {
        $(this).remove();
    });
    return $modal;
}

/**
 * [изм.9779 н.0000172239]
 */
function globalCheckFIO() {
    // regPage LKPage modalCheckUserFIO
    $('body')
      // Событие ввода
      .on('input.check_fio', 'input#last_name, input#first_name, input#second_name', function () {
          try {
              _checkFIO($(this), true, false, false);
          } catch (e) {
              console.log(e);
          }
      })
      // событие когда инпут теряет фокус
      .on('blur.check_fio', 'input#last_name, input#first_name, input#second_name', function () {
          try {
              _checkFIO($(this), false, true, true);
          } catch (e) {
              console.log(e);
          }
          // this.value = _ucFirst(this.value.trim().replace(/^[\-]+|[\-]+$/g, ''))
      });

    function _checkFIO(_this, first_step, second_step, last_step) {

        var field_val = _this.val();

        // Нельзя в начале указать символы " ", "-"
        if (first_step) {
            var first_letter = field_val.charAt(0);
            if (first_letter === ' ' || first_letter === '-') {
                field_val = field_val.substr(1);
                _this.val(field_val);
            }

            if (!/^[а-яёa-z]+[\-\s]?[а-яёa-z]+[\-\s]?[а-яёa-z]*$/i.test(field_val)) {
                field_val = field_val.replace(/[^а-яёa-z\s\-]+/ig, '').replace(/[\-]+/g, '-').replace(/[\s]+/g, ' ');
                _this.val(field_val)
            }
        }

        // Нельзя в конце указать символы " ", "-"
        if (second_step) {
            var last_char        = field_val.substr(-1),
                penultimate_char = field_val.substr(-2, 1);
            if (penultimate_char === '-' || penultimate_char === ' ') {
                if (last_char === '-' || last_char === ' ') {
                    field_val = field_val.substr(0, field_val.length - 1)
                    _this.val(field_val);
                }
            }
        }

        // символы " ", "-" изм множества преврашаем в один
        if (last_step) {
            _this.val($.trim(_ucFirst(field_val.replace(/\s{2,}/g, ' ').replace(/-{2,}/g, '-').replace(/^[\-]+|[\-]+$/g, ''))));
        }
    }

    /**
     * Преобразуем первую букву в верхний регистр остальные в нижний
     * @param str
     * @returns {string|*}
     */
    function _ucFirst(str) {
        if (!str) {
            return str;
        }

        return str[0].toUpperCase() + str.slice(1);
    }
}
