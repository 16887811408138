/**
 * Created by a.gladkih on 10.02.15.
 */

/**
 * Небольшой фикс
 */
try {
    var matched, browser;

    jQuery.uaMatch = function (ua) {
        ua = ua.toLowerCase();

        var match = /(chrome)[ \/]([\w.]+)/.exec(ua) ||
            /(webkit)[ \/]([\w.]+)/.exec(ua) ||
            /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) ||
            /(msie)[\s?]([\w.]+)/.exec(ua) ||
            /(trident)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
            ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
            [];

        return {
            browser: match[1] || "",
            version: match[2] || "0"
        };
    };

    matched = jQuery.uaMatch(navigator.userAgent);
    matched.browser = matched.browser == 'trident' ? 'msie' : matched.browser;
    browser = {};

    if (matched.browser) {
        browser[matched.browser] = true;
        browser.version = matched.version;
    }

// Chrome is Webkit, but Webkit is also Safari.
    if (browser.chrome) {
        browser.webkit = true;
    } else if (browser.webkit) {
        browser.safari = true;
    }

    jQuery.browser = browser;

    var type_stream = '';
    if (!window.WebSocket && !window.MozWebSocket) {
        type_stream = 'longpolling';
    } else {
        if ($.browser.chrome) {
            type_stream = 'websocket||longpolling';
        } else if (browser.safari) {
            type_stream = 'longpolling';
        } else if (browser.msie && browser.version < 10) {
            type_stream = 'longpolling';
        } else {
            type_stream = 'websocket||longpolling';
        }
    }

    var typeSSL = false;
    if (location.protocol == 'https:') {
        typeSSL = true;
    }

    var pushstream = new PushStream({
        useSSL: typeSSL,
        host: window.location.hostname,
        port: window.location.port,
        modes: type_stream,
        messagesPublishedAfter: 5,
        messagesControlByArgument: true
    });
    pushstream.onmessage = messageReceived;
    pushstream.addChannel('info_all_users');
    pushstream.connect();

    /*Р.6001 Н.141819*/
    function messageReceived(text, id, channel) {
        var values = $.parseJSON(text);

        if (values.action !== 'notice_on_the_site') return;

        var _text = values.text.replace(/\\r/g, '\r').replace(/\\n/g, '\n').replace(/str_slash/g, '\\').replace(/str_reverseSlash/g, "/").replace(/str_quotes/g, "\"");

        var content = '<div id="message-modal' + id + '" data-role="message" class="modal fade" tabindex="-1" role="dialog">' +
            '<div class="vertical-alignment-helper">' +
            '<div class="modal-dialog vertical-align-center" style="vertical-align: middle;" role="document">' +
            '<div class="modal-content modal-content-center">' +
            '<div class="modal-header">' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' +
            '<h4 class="modal-title">' + values.title + '</h4>' +
            '</div>' +
            '<div class="modal-body">' + _text + '</div>' +
            '<div class="modal-footer"><button type="button" class="btn btn-primary" data-dismiss="modal"><span class="glyphicon glyphicon-remove"></span> ' +
            i18next.t('Закрыть') +
            '</button></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';

        var $prevModal =  $('div[data-role="message"]');

        $prevModal.modal('hide');

        $('body').prepend(content);

        $('#message-modal' + id).modal({
            show: true,
            keyboard: true,
            backdrop: true
        });
    };
} catch (e) {}
/*$(document).ready(function() {
    window.onunload = function () {
        pushstream.removeAllChannels();
        pushstream.disconnect();
    }
});*/
